import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Link from "components/Typography/Link.js";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CustomCheckbox from "components/CustomInput/CustomCheckbox.js"
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import Pagination from "components/Navbars/Pagination.js";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor, reset , config, sortCallback, sortered, paginate, dtaPage} = props;

  const interReset = reset;

  function sort(field){
    let dir = 'up'
    if(sortered && sortered.field == field){
      dir = (sortered.dir == 'down')?'up':'down';
    }
    sortCallback(field,dir)
  } 
  //reset = false
  return (
   
    <div className={classes.tableResponsive}>
      
      {(dtaPage)?<Pagination callback={paginate} actions={dtaPage}/>:''}
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                    width={(prop.width)?prop.width:'auto'}
                  >
                    {(prop.type == "checkbox")?
                    <Checkbox
                          color="info"
                        />:""}

                      {(prop.sortable)?
                      <div
                       onClick={()=>{sort(prop.sortable)}}
                       style={{
                         cursor:'pointer'
                       }}
                       >
                        <span style={{
                          height: '100%',
                          display: 'block',
                          paddingTop: '3px',
                          float: prop.align ? prop.align : 'left'
                        }}
                        >
                        {prop.name}
                        </span> 
                        {(sortered && prop.sortable == sortered.field)?(sortered.dir=='up')?<ExpandMoreOutlinedIcon/>:<ExpandLessOutlinedIcon/>:''}
                      </div>
                    :prop.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {(tableData.length==0)?
            <TableRow key={'id'} className={classes.tableBodyRow}>
              <TableCell colSpan={(tableHead)?tableHead.length:1} style={{padding:'0px',textAlign:'center'}}>
                <span style={{color:'#D1D1D1'}}>No hay resultados disponibles</span>
              </TableCell>
            </TableRow>
            
          :""}
          {tableData.map((prop, key) => {
            const width = (100/prop.length)
            const total_rows = prop.length;
            const inactive = ('cfg' in prop[0])?prop[0].cfg.inactive : false;

            return (
              <TableRow key={key} className={classes.tableBodyRow}>
                {prop.map((prop, key) => {
                 //console.log(typeof(prop.value))
                  return (
                    <TableCell className={classes.tableCell} key={key} style={{
                      
                      textDecoration:(inactive)?'line-through':'none',
                      color:(inactive)?'red':'innerit',
                      textAlign: prop.align ? prop.align : ''
                    }}>
                    {
                      (prop.type==='delete')?
                        <div style={{cursor:'pointer'}} onClick={()=>{prop.callback(prop.valret)}}>
                          <DeleteOutlinedIcon style={{color:'#E8833F'}}/>
                        </div>
                        

                        :(prop.type == "checkbox")?
                        
                        <Checkbox
                              color="info"
                            />
                        :(prop.type == 'cscheckbox')?
                        
                        <CustomCheckbox
                          value={(typeof(prop.value)=='object')?JSON.stringify(prop.value):prop.value}
                          legends = {prop.legends || ['Activo','Inactivo']}
                          label = {prop.name}
                          onChange={prop.callback}
                          source={prop.source}
                          init={prop.ini}
                          resetable={prop.resetable}
                          reset ={interReset}
                        />
                        :""}
                    
                       {prop.type == "link" && !inactive?(prop.to!='')?
                       <Link to={prop.to} ext={prop.ext}>{prop.name}</Link>:
                       <Link to={prop.to} onClick={prop.action}>{prop.name}</Link>
                       :prop.name}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray"
};

