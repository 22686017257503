export const API_URI = 'https://api.tusclasesdeguitarra.com';
//export const API_URI = 'https://apitest.tusclasesdeguitarra.com';
//export const API_URI = 'http://localhost:8000';

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
