import React, {useState, useEffect} from "react";
import $ from "jquery";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Collapse from '@material-ui/core/Collapse';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomTextArea from "components/CustomInput/CustomTextArea.js";
import CustomSelect from "components/CustomInput/CustomSelect.js"
import CustomCheckbox from "components/CustomInput/CustomCheckbox.js";
import FormCheckbox from "components/CustomInput/FormCheckbox.js"
import CustomInputFile from "components/CustomInput/CustomInputFile.js"

import { API_URI } from '../../helpers';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};



const useStyles = makeStyles(styles);



export default function Countries() {
  const [data, setData] =  useState({
    headers:[{name:"Tipo",type:"text",sortable:'name'},{name:"P. local",type:"text"},{name:"P. usd",type:"text"},{name:"Pais",type:"text",sortable:'country_name'},{name:"Estado",type:"text"},{name:"",type:"text"},{name:"",type:"text"}],
    data:[],
    sorting:{},
    pagination:null
  });
  const [open, setOpen] =  useState(false);

  const initInputs = {
    id:'',    
    plan:'',
    price:'',
    priceUsd:'',
    discount:'',
    promoDetails:'',
    price_list:'',
    stripe_mult:''
  }
  const initErrors = {
    country:false,
    plan:false,
    price:false,
    priceUsd:false,
    discount:'',
    promoDetails:'',
    price_list:'',
    stripe_mult:''
  }

  const [inputs,setInputs] = useState({...initInputs,['country']:''});
  const [errors,setErrors] = useState(initErrors);

  const initTextSusc = {
    text:'',
    old:'',
    methods:[],
    methodsOrig:[],
    oldSubscriptionPayment:false,
    subscriptionPayment:false,
    oldSelectable_plan:false,
    selectable_plan:false,
    error:false,
    countryCover:null,
    oldCountryCover:null,
    countryCoverPricing:null,
    oldCountryCoverPricing:null,
    oldVimeo_video_home:null,
    oldVimeo_video_landing:null
  }
  const [textSusc,setTextSusc] = useState(initTextSusc);

  function handleTextSuscChanges(event){
    const name = event.target.id;
    setTextSusc({
        ...textSusc,
        [name]: (event.target.type == "checkbox")?(event.target.value=='true'):event.target.value
      });
  }

  function changeTextSusc(e){
    const value = e.target.value;
    setTextSusc({...textSusc,['text']:value});
  }

  function changeVimeoVideoHome(e){
    const value = e.target.value;
    setTextSusc({...textSusc,['vimeo_video_home']:value});
  }

  function changeVimeoVideoLanding(e){
    const value = e.target.value;
    setTextSusc({...textSusc,['vimeo_video_landing']:value});
  }

  function changeMethods(e){
    console.log(e)
    if(e.stat){
      textSusc.methods.push(e.value)
    }else{
      const indx = textSusc.methods.indexOf(e.value)
      textSusc.methods.splice(indx,1);
    }

    setTextSusc({...textSusc,['methods']:textSusc.methods});
  }

  function saveTextSusc(){
    const datos = new FormData();
    datos.append('id',inputs.country);   
    datos.append('text',textSusc.text);
    datos.append('methods',textSusc.methods);
    datos.append('subscriptionPayment',textSusc.subscriptionPayment);
    datos.append('selectable_plan',textSusc.selectable_plan);
    datos.append('countryCover',textSusc.countryCover);
    datos.append('countryCoverPricing',textSusc.countryCoverPricing);
    datos.append('vimeo_video_home',textSusc.vimeo_video_home);
    datos.append('vimeo_video_landing',textSusc.vimeo_video_landing);

    fetch(`${API_URI}/admin/country/saveText`,{
      method:'POST',
      body:datos
    })
    .then(res => res.json())
    .then(
      (result) => {
        if(result.result){
          getCfg();
          setTextSusc({
            ...textSusc,
            ['old']:textSusc.text,
            ['oldSubscriptionPayment']:textSusc.subscriptionPayment,
            ['oldSelectable_plan']:textSusc.selectable_plan,
            ['oldCountryCover']:textSusc.countryCover,
            ['oldCountryCoverPricing']:textSusc.countryCoverPricing,
            ['oldVimeo_video_home']:textSusc.vimeo_video_home,
            ['oldVimeo_video_landing']:textSusc.vimeo_video_landing,
          });
        }else{
          alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        }
      }
    )
  }

  const [cfg, setCfg] = useState({
    countries: [],
    plans: [],
    methods: [],
    status: [],
    prices:{},
    load:false,
  });

  function handleChanges(event){
    const name = event.target.id;
    setInputs({
        ...inputs,
        [name]: event.target.value,
      });
  }



  function toggleCategory(data){
    const datos = new FormData();
    datos.append('id',data.value.id);   
    datos.append('plan_id',data.value.plan_id);   
    datos.append('country_id',data.value.country_id); 
    datos.append('value',data.stat);    
    fetch(`${API_URI}/admin/prices/toogle`,{
      method:'POST',
      body:datos
    })
    .then(res => res.json())
    .then(
      (result) => {
        
      }
    )
  }

  function deletePlan(ids){
    const check = window.confirm('Esta seguro que desea eliminar esta configuracion?')
    if(check){
      const datos = new FormData();
      datos.append('id',ids.id);   
      datos.append('plan_id',ids.plan_id);   
      datos.append('country_id',ids.country_id);   
      fetch(`${API_URI}/admin/prices/del`,{
        method:'POST',
        body:datos
      })
      .then(res => res.json())
      .then(
        (result) => {
          getList()
        }
      )
    }
    
  }

  function toEdit(ids){
    const datos = new FormData();
    datos.append('id',ids.id);     
    datos.append('country_id',ids.country_id);   
    fetch(`${API_URI}/admin/prices/edit`,{
      method:'POST',
      body:datos
    })
    .then(res => res.json())
    .then(
      (result) => {
        const aux = {
          id:result.pivot.id,
          country:result.pivot.country_id,
          plan:result.id,
          price:result.pivot.price,
          priceUsd:result.pivot.price_usd,
          discount:result.pivot.discount,
          promoDetails:result.pivot.promo_details,
          price_list:result.pivot.list_price,
          stripe_mult: result.pivot.stripe_mult ? result.pivot.stripe_mult : ''
        }
        setInputs(aux);
      }
    )
  }

  function clearForm(){
    setInputs({...inputs,...initInputs});
    setErrors(initErrors);
  }

  function save(event){
    event.preventDefault()
    const datos = new FormData();

    var hasErrors = false;
    var aux = {};
    Object.keys(inputs).forEach(function(k){
      
      if(k!='imageUrl'){
        if($('#'+k).length > 0 ){
          if('validity' in $('#'+k)[0] && !$('#'+k)[0].validity.valid){
              hasErrors = true;
              aux[k] = true
            }
        }
        datos.append(k,inputs[k]);
      }
    })
    
    if(hasErrors){
      setErrors({
        ...errors,
        ...aux
      })
      $('#'+Object.keys(aux))[0].focus();
      return false
    }

    fetch(`${API_URI}/admin/prices/save`,{
      method:'POST',
      body:datos
    })
    .then((res)=>{
      if(res.status != 200){
        alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        return false
      }
      return res.json()
    })
    .then(
      (result) => {
        if(result.result){
          //setOpen(!open);
          getList();
          clearForm();
        }
      }
    )
  }


  function ordering(field,dir){
    const obj = {field:field,dir:dir}
    setData({
      ...data,
      ['data']:[],
      ['sorting']:obj
    })
    getList(obj);
  }
  

  function getList(params = null){
    if(!cfg.load){
      getCfg()
    }
    if(inputs.country){
      setTextSusc({
        ...textSusc,
        ['text']:getData(inputs.country,'subs_message'),
        ['old']:getData(inputs.country,'subs_message'),
        ['methods'] : getData(inputs.country,'pay_channels').slice(),
        ['methodsOrig'] : getData(inputs.country,'pay_channels').slice(),
        ['subscriptionPayment']:Boolean(getData(inputs.country,'subscription_payment')),
        ['oldSubscriptionPayment']:Boolean(getData(inputs.country,'subscription_payment')),
        ['selectable_plan']:Boolean(getData(inputs.country,'selectable_plan')),
        ['oldSelectable_plan']:Boolean(getData(inputs.country,'selectable_plan')),
        ['countryCover']:getData(inputs.country,'cover'),
        ['oldCountryCover']:getData(inputs.country,'cover'),
        ['countryCoverPricing']:getData(inputs.country,'cover_pricing'),
        ['oldCountryCoverPricing']:getData(inputs.country,'cover_pricing'),
        ['vimeo_video_home']:getData(inputs.country,'vimeo_video_home'),
        ['oldVimeo_video_home']:getData(inputs.country,'vimeo_video_home'),
        ['vimeo_video_landing']:getData(inputs.country,'vimeo_video_landing'),
        ['oldVimeo_video_landing']:getData(inputs.country,'vimeo_video_landing'),
      });
    }else{
      clearForm();
    }

    const extra = new FormData();
    const sort = (data.sorting)?JSON.parse(JSON.stringify(data.sorting)):null;
    if(params){
      if(params.field){
        extra.append('order',params.field);
        extra.append('direc',params.dir)
      }else if(sort){
        extra.append('order',sort.field);
        extra.append('direc',sort.dir)
      }
      if(params.page){
        extra.append('page',params.page);
      }else{
        extra.append('page',data.pagination.current);
      }
      
    }

    if(inputs.country!=""){
      extra.append('country',inputs.country);
    }
    fetch(`${API_URI}/admin/prices/plans`,{method:'POST',body:extra})
    .then(res => res.json())
    .then(
      (result) => {
        const datos = [];
        result.data.forEach(function(v,k){
          var items = [
            {name:v.name,type:'text'},
            {name:v.coin+" "+v.pivot.price,type:'text'},
            {name:"U$D "+v.price_usd,type:'text'},
            {name:v.country_name,type:'text'},
            {name:'',type:'cscheckbox',value:{id:v.pivot.id,plan_id:v.id,country_id:v.pivot.country_id},ini:!v.pivot.inactive,legends:['Activo','Inactivo'],callback:toggleCategory},
            {name:"editar",type:'link',to:'',action:()=>{toEdit({id:v.pivot.id,country_id:v.pivot.country_id})}},
            {name:"",type:'delete',callback:deletePlan,valret:{id:v.pivot.id,plan_id:v.id,country_id:v.pivot.country_id}},
          ]
          datos.push(items);
          
        })
        setData({
          ...data,
          ['data']:datos,
          ['pagination'] : {'current':result.current_page,'next':result.next_page_url,'prev':result.prev_page_url,'total':result.last_page},
          ['sorting']:(params && params.field)?params:sort
        })
      })
  }


  async function getCfg() {
    const resp = await fetch(
      `${API_URI}/admin/student/cfg`
    );
    const json = await resp.json();
    setCfg({...json,['load']:true});
  }

  function getData(search,item){
    if(search){
      return cfg.countries.find(elem => elem.value==search)[item] || '';
    }
    return '';
  }

  function calcUsd(){
    if(parseInt(inputs.price)!=0){
      let usd = getData(inputs.country,'usd_equiv');
      return Math.round(parseInt(inputs.price)/usd);
    }else{
      return ''
    }    
  }

  function changeImageCover(elem){
    setTextSusc({...textSusc,['countryCover']:elem});
  }

  function changeImageCoverPricing(elem){
    setTextSusc({...textSusc,['countryCoverPricing']:elem});
  }
  
  useEffect(getList,[inputs.country])
  const classes = useStyles();
  return (
    <GridContainer>
      

      <GridItem xs={12} sm={12} md={12} style={{marginTop:'40px'}}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4} style={{textAlign:'right',marginTop:'-90px', zIndex:'1040'}}>
            <CustomSelect
                options={cfg.countries}
                id="country"
                value={inputs.country}
                required={true}
                error={false}
                onChange={handleChanges}
                label="País"
                name="paisSubsc"
            />
          </GridItem>
          
        </GridContainer>
      </GridItem>
      
      <GridItem xs={12} sm={12} md={10}>
        <Collapse in={inputs.country}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <h4 style={{marginTop:'8px'}}>Metodos habilitados.</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <GridContainer>
                  {cfg.methods.map((prop,k)=>{
                    return (
                      <GridItem xs={12} sm={12} md={4}>
                        {/* <FormCheckbox
                            id='lessonFree'
                            label = {prop.display}
                            onChange={()=>{}}
                            value={inputs.lessonFree}
                        /> */}

                        <CustomCheckbox
                          value={prop.value}
                          legends = {null}
                          label = {prop.display}
                          onChange={changeMethods}
                          init={textSusc.methods.includes(prop.value)}
                        />
                      </GridItem>
                    )
                  })}
                  
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} >
                <CustomTextArea
                  labelText="Texto descriptivo por país"
                  id="countryText"
                  value={textSusc.text}
                  onChange={changeTextSusc}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    placeholder:'Lorem Ipsun...',
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} >
                <CustomInput
                  labelText="Link Vimeo Home"
                  id="vimeo_video_home"
                  value={textSusc.vimeo_video_home}
                  onChange={changeVimeoVideoHome}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required:true,
                    placeholder:'',
                    type:'text',
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} >
                <CustomInput
                  labelText="Link Vimeo Landing"
                  id="vimeo_video_landing"
                  value={textSusc.vimeo_video_landing}
                  onChange={changeVimeoVideoLanding}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required:true,
                    placeholder:'',
                    type:'text',
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4} >
                <FormCheckbox
                    id='subscriptionPayment'
                    label="Pago por Suscripción"
                    onChange={handleTextSuscChanges}
                    value={textSusc.subscriptionPayment}
                  />
              </GridItem>
              <GridItem xs={12} sm={12} md={4} >
                <FormCheckbox
                    id='selectable_plan'
                    label="Planes por Duración"
                    onChange={handleTextSuscChanges}
                    value={textSusc.selectable_plan}
                  />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                
                {(textSusc.text != textSusc.old || 
                  textSusc.subscriptionPayment != textSusc.oldSubscriptionPayment || 
                  textSusc.selectable_plan != textSusc.oldSelectable_plan || 
                  textSusc.methods.length != textSusc.methodsOrig.length || 
                  textSusc.countryCover != textSusc.oldCountryCover ||
                  textSusc.countryCoverPricing != textSusc.oldCountryCoverPricing ||
                  textSusc.vimeo_video_home != textSusc.oldVimeo_video_home ||
                  textSusc.vimeo_video_landing != textSusc.oldVimeo_video_landing)?
                  <Button onClick={saveTextSusc} color="info">Guardar</Button>
                  :<Button disabled={true} color="info">Guardado</Button>
                }
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card profile>
            <CardBody profile>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <h4>Imagen Home</h4>
                  <CustomInputFile
                    value={textSusc.countryCover}
                    onChange={changeImageCover}
                    typeBtn='link'
                    preview={true}
                  />              
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <h4>Imagen Pricing</h4>
                  <CustomInputFile
                    value={textSusc.countryCoverPricing}
                    onChange={changeImageCoverPricing}
                    typeBtn='link'
                    preview={true}
                  />              
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
             <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                      labelText="Moneda Local"
                      id="categoryName"
                      value={getData(inputs.country,'coin_cod')}
                      
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder:'ARS',
                        disabled:true
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                      labelText="Equiv. USD"
                      id="categoryName"
                      value={getData(inputs.country,'usd_equiv')}
                      
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder:'64.5',
                        disabled:true
                      }}
                    />
                </GridItem>
                
                <GridItem xs={12} sm={12} md={3}>
                  <CustomSelect
                      options={cfg.plans}
                      id="plan"
                      value={inputs.plan}
                      required={true}
                      error={errors.plan}
                      onChange={handleChanges}
                      label="Plan"
                      name="paisSubsc"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                      labelText="Detalle Promo"
                      id="promoDetails"
                      value={inputs.promoDetails}
                      error={errors.promoDetails}
                      onChange={handleChanges}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                      labelText="Valor USD"
                      id="priceUsd"
                      value={calcUsd()}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required:true,
                        placeholder:'USD 10',
                        type:'number',
                        disabled:true,
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                      labelText="Precio lista"
                      id="price_list"
                      value={inputs.price_list}
                      error={errors.price_list}
                      onChange={handleChanges}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required:true,
                        placeholder:'$ 500',
                        type:'number',
                        min:'0',
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                      labelText="Valor moneda local"
                      id="price"
                      value={inputs.price}
                      error={errors.price}
                      onChange={handleChanges}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required:true,
                        placeholder:'$ 450',
                        type:'number',
                        min:'0',
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                      labelText="% Descuento"
                      id="discount"
                      value={inputs.discount}
                      error={errors.discount}
                      onChange={handleChanges}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required:true,
                        placeholder:'10%',
                        type:'number',
                        min:'0',
                        max:'100'
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                      labelText="Multiplicador Stripe"
                      id="stripe_mult"
                      value={inputs.stripe_mult}
                      error={errors.stripe_mult}
                      onChange={handleChanges}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={3} style={{alignSelf:'flex-end', marginBottom:'10px'}}>
                  <Button onClick={save} color="info" >Agregar precio</Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={3} style={{alignSelf:'flex-end', marginBottom:'10px'}}>
                  <Button onClick={clearForm} color="gray" >Cancelar</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Collapse>
      </GridItem>
      
    
      <GridItem xs={12} sm={12} md={10}>
      <h4 style={{paddingLeft:'20px'}}>Listado de precios creados.</h4>
        <Card>
          <CardBody>
            <Table
              tableHeaderColor="info"
              tableHead={data.headers}
              tableData={data.data}
              sortered={data.sorting}
              sortCallback={ordering}
              paginate={getList}
              dtaPage = {data.pagination}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
