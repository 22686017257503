import React, { useState, useEffect } from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import AsyncAutocomplete from 'components/CustomInput/AsyncAutocomplete.js'
import Titulo1 from "components/Typography/Titulo1.js"

import { API_URI } from '../../helpers';

export default function RecomendedCourses(props){
    const {courseId, initial} = props;

    const [inputRecomende,setInputRecomended] = useState(initial);


    async function changeRecomended(e,val){
        //const ids = val.map((elem)=>{return elem.id});
        
        const data = new FormData();
        data.append('course',courseId);
        val.forEach(element => {
            data.append('courses[]',parseInt(element.id))
        });

        const resp = await fetch(`${API_URI}/admin/courses/recomended/sync`,{
                                method:'POST',
                                body:data
                            }).catch(function(error) {
                              alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
                            });
        const json = await resp.json();

        if(json.result){
            setInputRecomended(val);
        }else{
          alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        }
    }


    return (
        <GridContainer >
            <GridItem xs={12} sm={12} md={12}> 
              <Card>
                <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Titulo1>Cursos recomendados</Titulo1>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <AsyncAutocomplete 
                          multiple
                          url={`${API_URI}/admin/courses/recomended/`+courseId}
                          id="recomendedCourses"
                          label="Desde"
                          asyncProps={{
                            multiple:'multiple',
                            filterSelectedOptions:true
                          }}
                          val={inputRecomende}
                          onChange={changeRecomended}
                        />
                      </GridItem>
                    </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
    )
}