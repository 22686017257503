import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CollapseRow from "components/Table/CollapseRow.js"
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import Pagination from "components/Navbars/Pagination.js";
import { Collapse } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function TableExpandable(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor, config, sortCallback, sortered, paginate, dtaPage} = props;

  function sort(field){
    let dir = 'up'
    if(sortered && sortered.field == field){
      dir = (sortered.dir == 'down')?'up':'down';
    }
    sortCallback(field,dir)
  }
  return (
    <div className={classes.tableResponsive}>
      {(dtaPage)?<Pagination callback={paginate} actions={dtaPage}/>:''}
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
            <TableCell align="left" width='25px' style={{minWidth:'25px'}} >
                
            </TableCell>
              {tableHead.map((prop, key) => {
                
                return (
                  <TableCell
                  align="left"
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    width={config[key]}
                    style={{minWidth:config[key]}}
                    key={key}
                  >
                    {(prop.type == "checkbox")?
                    <Checkbox
                          color="info"
                        />:""}
                    {(prop.sortable)?
                      <div
                       onClick={()=>{sort(prop.sortable)}}
                       style={{
                         cursor:'pointer'
                       }}
                       >
                        <span style={{
                          height: '100%',
                          display: 'block',
                          paddingTop: '3px',
                          float: 'left'
                        }}
                        >
                        {prop.name}
                        </span> 
                        {(sortered && prop.sortable == sortered.field)?(sortered.dir=='up')?<ExpandMoreOutlinedIcon/>:<ExpandLessOutlinedIcon/>:''}
                      </div>
                    :prop.name}
                    
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {(tableData.length==0)?
            <TableRow key={'id'} className={classes.tableBodyRow}>
              <TableCell colSpan={tableHead.length+1} style={{padding:'0px',textAlign:'center'}}>
                <span style={{color:'#D1D1D1'}}>No hay resultados disponibles</span>
              </TableCell>
            </TableRow>
            
          :""}
          {tableData.map((prop, key) => {
            const width = (100/prop.items.length)
            const total_rows = prop.items.length;
            return (
              <TableRow key={key} className={classes.tableBodyRow}>
                <TableCell width="100%" colSpan={total_rows+1} style={{padding:'0px'}}>
                  <CollapseRow items={prop.items} extras={prop.expand} width={width} total_rows={total_rows} config={config}/>
                </TableCell>
              </TableRow>
              
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

TableExpandable.defaultProps = {
  tableHeaderColor: "gray"
};

// CustomTable.propTypes = {
//   tableHeaderColor: PropTypes.oneOf([
//     "warning",
//     "primary",
//     "danger",
//     "success",
//     "info",
//     "rose",
//     "gray"
//   ]),
//   tableHead: PropTypes.arrayOf(PropTypes.string),
//   tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
// };