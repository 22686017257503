import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";

import Collapse from '@material-ui/core/Collapse';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomTextArea from "components/CustomInput/CustomTextArea.js"
import Button from "components/CustomButtons/Button.js";
import ProgressButton from "components/CustomButtons/ProgressButton.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js"
import CustomInputFile from "components/CustomInput/CustomInputFile.js"
import FormCheckbox from "components/CustomInput/FormCheckbox.js"
import $ from "jquery";

import { API_URI } from '../../helpers';

export default function SubFormsLessons(props) {

    const {onChange, id, course} = props
    const [inputs,setInputs] = useState({
      lessonName:"",
      lessonVideo:'',
      lessonDescription:'',
      lessonBacktrack:null,
      lessonTab:null,
      lessonId:false,
      lessonFree:false,
      courseId:course,
    })

    const errorsInit = {
        lessonName:false,
        lessonVideo:false,
        lessonDescription:false,
        lessonBacktrack:false,
        lessonTab:false,
        lessonFree:false,
    }

    const [errors,setErrors] = useState(errorsInit);
    const [loading,setLoading] = useState(false);
    const refer = React.useRef(null)

    useEffect(()=>{
        if(id){
            setClassForm(true)
             setTimeout(function(){
                // const layout = document.getElementsByClassName('makeStyles-mainPanel-2');
                // layout[0].scrollTo(0, refer.current.offsetTop-50)
                $('#main-body').animate({
                    scrollTop:(refer.current.offsetTop-80)+"px",
                },800)
            },)
            fetch(`${API_URI}/admin/lesson/get/`+id)
            .then(res => res.json())
            .then(
                (result) => {
                    //if(result.result){
                        //setClassForm(true)
                        setInputs({
                            lessonName:result.name,
                            lessonVideo:result.video,
                            lessonDescription:result.description,
                            lessonBacktrack:result.backtrack,
                            lessonTab:result.tablatura,
                            lessonId:result.id,
                            lessonFree:result.free,
                            courseId:course
                        })
                        
                    //}
                }
            ).catch(function(error) {
                alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
              });
            
        } 


        
        
    },[id])

    function resetForm(){
        setInputs({
            lessonName:"",
            lessonVideo:'',
            lessonDescription:'',
            lessonBacktrack:'',
            lessonTab:'',
            lessonId:false,
            lessonFree:false,
            courseId:course
          })
        setErrors(errorsInit);
    }



    function handleOpen(){
        setClassForm(!classForm)
        if(!classForm){
            setTimeout(function(){
                $('#main-body').animate({
                    scrollTop:(refer.current.offsetTop-80)+"px",
                },800)
            },)
        }
        if(classForm){
            resetForm()

            onChange(false);
        }
    }

    function handleChanges(event){
        const name = event.target.id;
        
        setInputs({
            ...inputs,
            [name]:(event.target.type == "checkbox")?(event.target.value=='true'):event.target.value
          });
    }

    function changeBackT(elem){
        console.log('lesson',elem)
        setInputs({
            ...inputs,
            ['lessonBacktrack']: elem
          });
      }

    function changeTab(elem){
        setInputs({
            ...inputs,
            ['lessonTab']: elem
          });
    }

    function saveLesson(event){
        setLoading(true);
        event.preventDefault()
        const data = new FormData();
        var hasErrors = false;
        var aux = {};
        Object.keys(inputs).forEach(function(k){
        if(k!='imageUrl'){
            if($('#'+k).length > 0 ){
            if('validity' in $('#'+k)[0] && !$('#'+k)[0].validity.valid){
                hasErrors = true;
                aux[k] = true
                }
            }
            data.append(k,inputs[k]); 
        }
        })

        if(hasErrors){
            setLoading(false);
            setErrors({
                ...errors,
                ...aux
            })
            $('#'+Object.keys(aux))[0].focus();
            
            return false
        }
   
        fetch(`${API_URI}/admin/lesson/save`,{
            method:'POST',
            body:data
        })
        .then((res)=>{
            if(res.status != 200){
              alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
              return false
            }
            return res.json()
          })
        .then(
            (result) => {
                setLoading(false);
                if(result.result){
                    onChange(result.insert)
                    resetForm()
                    setClassForm(false)
                }else{
                    alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
                  }
            }
        )
    }
  
    const [classForm,setClassForm]  = useState(false);
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}> 
                <Card>
                    <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={10}>
                        <h3>Agregar Clases</h3>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2}>
                        <Button onClick={handleOpen}>Add</Button>
                        </GridItem>
                    </GridContainer>
                    </CardBody>
                </Card>
                <Collapse in={classForm}>
                    <div ref={refer}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput 
                                    labelText="Nombre de la clase."
                                    id="lessonName"
                                    value={inputs.lessonName}
                                    onChange={handleChanges}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    error={errors.lessonName}
                                    inputProps={{
                                        required:true,
                                    }}
                                    disabled={loading}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomTextArea 
                                    labelText="Descripción de la clase."
                                    id="lessonDescription"
                                    value={inputs.lessonDescription}
                                    onChange={handleChanges}
                                    error={errors.lessonDescription}
                                    inputProps={{
                                        required:true,
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        placeholder: 'Agregar descripción'
                                    }}
                                    disabled={loading}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput 
                                    labelText="Video de la clase."
                                    id="lessonVideo"
                                    onChange={handleChanges}
                                    value={inputs.lessonVideo}
                                    error={errors.lessonVideo}
                                    inputProps={{
                                        required:true,
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        placeholder: 'Cargar URL delvideo'
                                    }}
                                    disabled={loading}
                                    />
                                </GridItem>
                
                                <GridItem xs={12} sm={12} md={6}>
                                    
                                    <CustomInputFile
                                        onChange={changeBackT}
                                        accept="audio/*"
                                        value={inputs.lessonBacktrack}
                                        label="Backtrack"
                                    />  
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInputFile
                                        onChange={changeTab}
                                        accept="image/*,.pdf"
                                        value={inputs.lessonTab}
                                        label="Tablatura"
                                    />  
                                </GridItem>
                                
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <GridContainer style={{width:'100%'}}>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <FormCheckbox
                                            id='lessonFree'
                                            label = 'Clase libre'
                                            onChange={handleChanges}
                                            value={inputs.lessonFree}
                                            disabled={loading}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={8} style={{textAlign:'right'}}>
                                        <Button color="gray" onClick={handleOpen} disabled={loading}>
                                            Cancelar
                                        </Button>
                                        <ProgressButton color="info" onClick={saveLesson} loading={loading} loadingMsg="Subiendo...">
                                            Guardar Clase
                                        </ProgressButton>
                                    </GridItem>
                                
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </div>
                </Collapse>
                
            </GridItem>
        </GridContainer>
    );
  }
  