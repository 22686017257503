import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import $ from "jquery";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomTextArea from "components/CustomInput/CustomTextArea.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import CustomInputFile from "components/CustomInput/CustomInputFile.js"
import Titulo1 from "components/Typography/Titulo1.js"
import CustomSelect from "components/CustomInput/CustomSelect.js"
import Link from "components/Typography/Link.js";
import Divider from "@material-ui/core/Divider";
import AsyncAutocomplete from 'components/CustomInput/AsyncAutocomplete.js'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { List, ListItem, ListItemText, ListItemSecondaryAction } from "@material-ui/core";

import { API_URI } from '../../helpers';

export default function RewardsCourses(props) {

    const {course} = props

    const [rewardLessonsList,setRewardLessonsList] = useState([])
    const [rewardPercentList,setRewardPercentList] = useState([])
    const [ autoComplete, setAutoComplete] = useState({to:{},from:{}});
    const [loading,setLoading] = useState(false);

    async function getRewardLessons(){
      const response = await fetch(`${API_URI}/admin/reward/courses/`+course+'/getLessons');
      const json = await response.json();

      setRewardLessonsList(json);
    }

    async function getRewardPercent(){
      const response = await fetch(`${API_URI}/admin/reward/courses/`+course+'/getPercent');
      const json = await response.json();

      setRewardPercentList(json);
    }

    async function detachReward(id,type){
      const response = await fetch(`${API_URI}/admin/reward/courses/`+course+'/del/'+id);
      const json = await response.json();

      if(json.result){
        (type=='percent')?getRewardPercent():getRewardLessons();
      }
    }

    

    const [inputsLess, setInputsLess] = useState({
        rewardLessonGroupFrom:{},
        rewardLessonGroupTo:{},
        RewardDescription:'',
        RewardTitle:'',
        RewardCant:'',
        rewardImage:'',
        rewardId:false,
        courseId:course,
    })

    const initErrorsP = {
      rewardPercent:false,
      RewardDescriptionP:false,
      RewardCantP:false,
      RewardTitleP:false,
    }
    const [errorsP,setErrorsP] = useState(initErrorsP);

    const initErrorsL = {
      rewardLessonGroupFrom:false,
      rewardLessonGroupTo:false,
      rewardPercent:false,
      RewardDescription:false,
      RewardCant:false,
      RewardTitle:false,
    }
    const [errorsL,setErrorsL] = useState(initErrorsP);

    const [inputsPercen, setInputsPercen] = useState({
      rewardPercent:'',
      RewardDescriptionP:'',
      RewardTitleP:'',
      RewardCantP:'',
      rewardImage:'',
      rewardId:false,
      courseId:course,
  })

  useEffect(()=>{
    getRewardLessons();
    getRewardPercent();
    
  },[])

    function handleChanges(event){
        const name = event.target.id;
        setInputsLess({
            ...inputsLess,
            [name]: event.target.value,
          });
      }

      function handleChangesPercen(event){
        const name = event.target.id;
        setInputsPercen({
            ...inputsPercen,
            [name]: event.target.value,
          });
      }

    function editPercent(id){
      const elem = rewardPercentList.find(element => element.id == id);
      setInputsPercen({
        rewardPercent:elem.pivot.value,
        RewardDescriptionP:elem.description,
        RewardTitleP:elem.title,
        RewardCantP:elem.count,
        rewardImage:elem.image,
        rewardId:elem.id,
        courseId:course,
      })
    }

    function editLesson(id){
      const elem = rewardLessonsList.find(element => element.id == id);

      setInputsLess({
        rewardLessonGroupFrom:elem.val[0],
        rewardLessonGroupTo:elem.val[1],
        RewardDescription:elem.description,
        RewardTitle:elem.title,
        RewardCant:elem.count,
        rewardImage:elem.image,
        rewardId:elem.id,
        courseId:course,
      })
    }

    function changeImageLess(elem){
        setInputsLess({
          ...inputsLess,
          ['rewardImage']: elem
        });
    }

    function changeImagePercen(elem){
      // inputsLess.rewardImage = elem
      setInputsPercen({
        ...inputsPercen,
        ['rewardImage']: elem
      });
  }

    function saveLessonsReward(event){
      setLoading(true)
      event.preventDefault()
      const data = new FormData();
      var hasErrors = false;
      var aux = {};
      Object.keys(inputsLess).forEach(function(k){
        if($('#'+k).length > 0 ){
          if('validity' in $('#'+k)[0] && !$('#'+k)[0].validity.valid){
            hasErrors = true;
            aux[k] = true
            }
        }
        if(k=='rewardLessonGroupFrom' || k=='rewardLessonGroupTo'){
          data.append(k,inputsLess[k].id);
        }else{
          data.append(k,inputsLess[k]);
        }
      })

      if(hasErrors){
        setLoading(false);
        setErrorsL({
            ...errorsL,
            ...aux
        })
        $('#'+Object.keys(aux))[0].focus();
        
        return false
      }
      fetch(`${API_URI}/admin/reward/courses/addLessons`,{
        method:'POST',
        body:data
      })
      .then(res => res.json())
      .then(
        (result) => {
          setLoading(false)
          if(result.result){
            setInputsLess({
              rewardLessonGroupFrom:{},
              rewardLessonGroupTo:{},
              RewardDescription:'',
              RewardTitle:'',
              RewardCant:'',
              rewardImage:'',
              rewardId:false,
              courseId:course,
            })
            getRewardLessons();
            setAutoComplete({to:{},from:{}});
            setErrorsL(initErrorsL)
          }else{
            alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
          }
        }).catch(function(error) {
          alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        });
    }

    function savePercentReward(event){
      event.preventDefault()
      setLoading(true);
      const data = new FormData();
      var hasErrors = false;
      var aux = {};
      Object.keys(inputsPercen).forEach(function(k){
        if($('#'+k).length > 0 ){
          if('validity' in $('#'+k)[0] && !$('#'+k)[0].validity.valid){
            hasErrors = true;
            aux[k] = true
            }
        }
        data.append(k.replace(/P$/,''),inputsPercen[k]);
      })
      if(hasErrors){
        setLoading(false);
        setErrorsP({
            ...errorsP,
            ...aux
        })
        $('#'+Object.keys(aux))[0].focus();
        
        return false
      }
      fetch(`${API_URI}/admin/reward/courses/addPercent`,{
        method:'POST',
        body:data
      })
      .then((res)=>{
        if(res.status != 200){
          alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
          return false
        }
        return res.json()
      })
      .then(
        (result) => {
          setLoading(false);
          if(result.result){
            setInputsPercen({
              rewardPercent:'',
              RewardDescriptionP:'',
              RewardTitleP:'',
              RewardCantP:'',
              rewardImage:'',
              rewardId:false,
              courseId:course,
          })
          getRewardPercent();
          setErrorsP(initErrorsP)
          }else{
            alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
          }
        }).catch(function(error) {
          setLoading(false);
          alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        });
    }
    return (
        <GridContainer >
        <GridItem xs={12} sm={12} md={12}> 
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Titulo1>Premios para este curso</Titulo1>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <h3 >Premios por grupos de clase</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  Determinar clases.
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <AsyncAutocomplete 
                        url={`${API_URI}/admin/courses/lessons/`+course}
                        id="rewardLessonGroupFrom"
                        label="Desde"
                        error={errorsL.rewardLessonGroupFrom}
                        required={true}
                        val={inputsLess.rewardLessonGroupFrom}
                        onChange={(e,v)=>{
                          setInputsLess({
                            ...inputsLess,
                            ['rewardLessonGroupFrom']: v,
                          });
                        }}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                      <AsyncAutocomplete 
                        url={`${API_URI}/admin/courses/lessons/`+course}
                        id="rewardLessonGroupTo"
                        label="Hasta"
                        val={inputsLess.rewardLessonGroupTo}
                        error={errorsL.rewardLessonGroupTo}
                        required={true}
                        onChange={(e,v)=>{
                          setInputsLess({
                            ...inputsLess,
                            ['rewardLessonGroupTo']: v,
                          });
                        }}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                </GridItem>
                <GridItem xs={12} sm={12} md={5} style={{marginTop:'24px'}}>
                  <CustomInputFile
                    value={inputsLess.rewardImage}
                    label="Subir imagen."
                    onChange={changeImageLess}
                    typeBtn='link'
                    preview={true}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <CustomInput
                   labelText="Título del Premio"
                   id="RewardTitle"
                   value={inputsLess.RewardTitle}
                   onChange={handleChanges}
                   placeholder=""
                   error={errorsL.RewardTitle}
                   formControlProps={{
                     fullWidth: true
                   }}
                   inputProps={{
                     name:"lessons",
                     required:true,
                     placeholder:'Agregar un Titulo'
                   }}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                   labelText="num de premio"
                   id="RewardCant"
                   value={inputsLess.RewardCant}
                   error={errorsL.RewardCant}
                   onChange={handleChanges}
                   placeholder=""
                   formControlProps={{
                     fullWidth: true
                   }}
                  
                   inputProps={{
                     name:"lessons",
                     type:'number',
                     min:'1',
                     required:true,
                     placeholder:'#'
                   }}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  <CustomTextArea
                   labelText="Descripción del Premio"
                   id="RewardDescription"
                   value={inputsLess.RewardDescription}
                   onChange={handleChanges}
                   placeholder=""
                   error={errorsL.RewardDescription}
                   formControlProps={{
                     fullWidth: true
                   }}
                   inputProps={{
                     required:true,
                     name:"lessons",
                     placeholder:'Agregar descripción'
                   }}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{marginTop:'24px', textAlign:'right'}}>
                  <Link to="" onClick={saveLessonsReward} loading={loading} loadingMsg={'subiendo'}>+ Guardar premio.</Link>
                </GridItem>
                 
                <GridItem xs={12} sm={12} md={10}>
                  <Card>
                    <CardHeader>
                      <Titulo1>Premios Actuales</Titulo1>
                    </CardHeader>
                    <CardBody>
                      <List >
                        {rewardLessonsList.map(value=> {
                          //const labelId = `checkbox-list-secondary-label-${value}`;
                          return (
                            <ListItem key={value.id} button>
                            
                              <ListItemText id={'reward'+value.id} primary={value.title+" ("+value.pivot.value[0]+' - '+value.pivot.value[1]+')'} />
                              <ListItemSecondaryAction>
                                  <EditOutlinedIcon onClick={()=>{editLesson(value.id)}} style={{color:'#E8833F',cursor:'pointer', paddingRight:'12px'}}/>
                                  <DeleteOutlinedIcon onClick={()=>{detachReward(value.id,'lessons')}} style={{color:'#E8833F',cursor:'pointer'}} />
                                
                                
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    </CardBody>
                  </Card>
               
                </GridItem>

                <Divider/>

                <GridItem xs={12} sm={12} md={12}>
                  <h3>Premios por porcentaje de curso completado:</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  Determinar porcentaje.
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomSelect
                        options={[{value:10,display:'10%'},{value:20,display:'20%'},{value:30,display:'30%'},{value:40,display:'40%'},{value:50,display:'50%'},{value:60,display:'60%'},{value:70,display:'70%'},{value:80,display:'80%'},{value:90,display:'90%'},{value:100,display:'100%'}]}
                        label="Porcentaje"
                        id="rewardPercent"
                        onChange={handleChangesPercen}
                        error={errorsP.rewardPercent}
                        required={true}
                        value={inputsPercen.rewardPercent}
                      />
                      
                </GridItem>
                <GridItem xs={12} sm={12} md={7}>
                </GridItem>
                <GridItem xs={12} sm={12} md={5} style={{marginTop:'24px'}}>
                  <CustomInputFile
                    value={inputsPercen.rewardImage}
                    label="Subir imagen."
                    onChange={changeImagePercen}
                    typeBtn='link'
                    preview={true}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <CustomInput
                   labelText="Título del Premio"
                   id="RewardTitleP"
                   value={inputsPercen.RewardTitleP}
                   onChange={handleChangesPercen}
                   error={errorsP.RewardTitleP}
                   placeholder=""
                   formControlProps={{
                     fullWidth: true
                   }}
                   inputProps={{
                     name:"lessons",
                     required:true,
                     placeholder:'Agregar un Titulo'
                   }}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                   labelText="num de premio"
                   id="RewardCantP"
                   value={inputsPercen.RewardCantP}
                   onChange={handleChangesPercen}
                   error={errorsP.RewardCantP}
                   placeholder=""
                   formControlProps={{
                     fullWidth: true
                   }}
                   inputProps={{
                     name:"lessons",
                     type:'number',
                     min:'1',
                     required:true,
                     placeholder:'#'
                   }}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  <CustomTextArea
                   labelText="Descripción del premio"
                   id="RewardDescriptionP"
                   value={inputsPercen.RewardDescriptionP}
                   onChange={handleChangesPercen}
                   error={errorsP.RewardDescriptionP}
                   placeholder=""
                   formControlProps={{
                     fullWidth: true
                   }}
                   inputProps={{
                     name:"lessons",
                     required:true,
                     placeholder:'Agregar descripción'
                   }}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{marginTop:'24px', textAlign:'right'}}>
                  <Link to="" onClick={savePercentReward} loading={loading} loadingMsg={'subiendo'}>+ Guardar premio</Link>
                </GridItem>

                <GridItem xs={12} sm={12} md={10}>
                  <Card>
                    <CardHeader>
                      <Titulo1>Premios Actuales</Titulo1>
                    </CardHeader>
                    <CardBody>
                      <List >
                        {rewardPercentList.map(value=> {
                          //const labelId = `checkbox-list-secondary-label-${value}`;
                          return (
                            <ListItem key={value.id} button>
                            
                              <ListItemText id={'reward'+value.id} primary={value.title+" ("+value.pivot.value+'%)'} />
                              <ListItemSecondaryAction>
                                  <EditOutlinedIcon onClick={()=>{editPercent(value.id)}} style={{color:'#E8833F',cursor:'pointer', paddingRight:'12px'}}/>
                                  <DeleteOutlinedIcon onClick={()=>{detachReward(value.id,'percent')}} style={{color:'#E8833F',cursor:'pointer'}} />
                                
                                
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    </CardBody>
                  </Card>
               
                </GridItem>

              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    )
}