import React, {useState, useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Link from "components/Typography/Link.js";

import { API_URI } from '../../helpers';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};





const useStyles = makeStyles(styles);

export default function ListTrash() {
  const [tableData, setTableData] =  useState({
    header:[
      {name:"",type:"text",width:"40px"},
      {name:"Nombre",type:"text",sortable:'name'},
      {name:"Tipo",type:"text",sortable:'source'},
      {name:"Categoría",type:"text"},
      {name:"fecha",type:"text",sortable:'created_at'},
      {name:"Restaurar",type:"text",sortable:'inactive'},
      {name:"",type:"text"}
    ],
    data:[],
    sorting:{}
  });
  const [toDelete,setToDelete] = useState([]);
  const [resetTable,setResetTable] = useState(false);

  function reset(){
    setResetTable(!resetTable);
  }

  function getList(params = null){
    //console.log()
    const sort = (tableData.sorting)?JSON.parse(JSON.stringify(tableData.sorting)):null;
    const extra = new FormData();
    if(params){
      extra.append('order',params.field);
      extra.append('direc',params.dir)
    }
    fetch(`${API_URI}/admin/trash`,{method:'POST',body:extra})
    .then(res => res.json())
    .then(
      (result) => {
        const data = [];
        if(result.length == 0){
          setTableData({
            ...tableData,
            ['data']:[],
            ['sorting']:(params)?params:sort
          })
        }
        result.forEach(function(v,k){
          var items = [
            // {name:"",type:'checkbox'},
            {name:'',type:'cscheckbox',value:v.id,source:v.source,ini:false,resetable:true,legends:['',''],callback:actionCheck},
            {name:v.name,type:'text'},
            {name:v.source,type:'text'},
            {name:'Generica',type:'text'},
            {name:v.created_at,type:'text'},
            {name:'Restaurar',type:'link',to:"",action:()=>{restore(v.id,v.source)}},
            {name:"",type:'delete',source:v.source,callback:destroy,valret:{id:v.id,source:v.source}},
          ]
          data.push(items);
          if(data.length == result.length){
            setTableData({
              ...tableData,
              ['data']:data,
              ['sorting']:(params)?params:sort
            })
          }
        })
      })
  }

  useEffect(getList,[])

  function ordering(field,dir){
    const obj = {field:field,dir:dir}
    setTableData({
      ...tableData,
      ['data']:[],
      ['sorting']:{field:field,dir:dir}
    })
    getList(obj);
  }

  function massiveDelete(){
    if(toDelete.length==0){
      alert('no ha seleccionado ningun curso')
    }else{
      destroy(toDelete);
    }

    
  }

  function destroyAll(){
    destroy(false);
  }

  function destroy(id){
    let sended = "";
    let msg = "";
    
    if(!id){
      msg = "Eliminara todos los elementos PERMANENTEMENTE";
    }
    else if(Array.isArray(id)){
      msg = "Eliminara todos los elementos seleccionados PERMANENTEMENTE";
      sended = JSON.stringify(id)
    }else{
      msg = "Este elemento sera eliminado PERMANENTEMENTE";
      sended = JSON.stringify([id])
    }
    const check = window.confirm(msg)
    if(check){
      const datos = new FormData();
      if(id){
        datos.append('ids',sended);
      }
      fetch(`${API_URI}/admin/trash/empty`,{
        method:'POST',
        body:datos
      })
      .then(res => res.json())
      .then(
        (result) => {
          getList()
          setToDelete([]);
          reset();
        }
      )
    }
    
  }
  function restore(id,source){
    const datos = new FormData();
    datos.append('id',id);
    datos.append('source',source);   
    fetch(`${API_URI}/admin/trash/restore`,{
      method:'POST',
      body:datos
    })
    .then(res => res.json())
    .then(
      (result) => {
        getList();
      }
    )
  }
  const listDelete = [];
  function actionCheck(data){
    if(data.stat){
      listDelete.push(data.value)
    }else{
      const indx = listDelete.indexOf(data.value)
      listDelete.splice(indx,1);
    }

    setToDelete(listDelete);
  }
  const classes = useStyles();
  return (
    <GridContainer>
      
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>
                {(tableData.data.length!=0)?
                  <Link to="" onClick={destroyAll}>Vaciar papelera</Link>
                :""}
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                {(toDelete.length!=0)?
                  <Link to="" onClick={massiveDelete}>Eliminar seleccionados</Link>
                :""}
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="info"
              tableHead={tableData.header}
              tableData={tableData.data}
              sortered={tableData.sorting}
              sortCallback={ordering}
              reset = {resetTable}
            />
          </CardBody>
        </Card>
      </GridItem>
     
    </GridContainer>
  );
}
