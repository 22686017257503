import React, {useState, useEffect} from "react";
import $ from "jquery";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Collapse from '@material-ui/core/Collapse';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomTextArea from "components/CustomInput/CustomTextArea.js";
import CustomSelect from "components/CustomInput/CustomSelect.js"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Link from "components/Typography/Link.js";
import {useCookies } from 'react-cookie';

import { API_URI } from '../../helpers';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};



const useStyles = makeStyles(styles);



export default function Mailing() {
  const [data, setData] =  useState({
    headers:[{name:"Nombre",type:"text"},{name:"Condición",type:"text"},{name:"Estado",type:"text"},{name:"",type:"text"},{name:"",type:"text"}],
    data:[],
    sorting:{},
    pagination:null
  });
  const [open, setOpen] =  useState(false);

  const initInputs = {
    id:'',    
    title:'',
    subject:'',
    text:'',
    event:'',
    time:'',
    target:'',
    days:'',
    
  }
  const initErrors = {
    title:false,
    subject:false,
    text:false,
    event:false,
    time:false,
    target:false,
    days:false
  }
  const [cookies, setCookie, removeCookie] = useCookies();
  const [inputs,setInputs] = useState(initInputs);
  const [errors,setErrors] = useState(initErrors);

  const [cfg, setCfg] = useState({
    targets: [],
    times: [],
    events: [],
    load:false,
  });

  function handleChanges(event){
    const name = event.target.id;
    setInputs({
        ...inputs,
        [name]: event.target.value,
      });
  }



  function toggle(data){
    const datos = new FormData();
    datos.append('id',data.value);
    datos.append('value',data.stat);    
    fetch(`${API_URI}/admin/mail/toogle`,{
      method:'POST',
      body:datos
    })
    .then(res => res.json())
    .then(
      (result) => {
        
      }
    )
  }

  function toEdit(id){
    fetch(`${API_URI}/admin/mail/edit/`+id)
    .then(res => res.json())
    .then(
      (result) => {
        const aux = {
          id:result.id,
          title:result.name,
          subject:result.subject,
          text:result.code,
          event:result.event_id,
          time:result.time,
          days:result.days,
          target:result.target,
        }
        setInputs(aux);
        setOpen(true);
        triggerScroll();
      }
    )
  }

  function save(event){
    event.preventDefault()
    const datos = new FormData();

    var hasErrors = false;
    var aux = {};
    Object.keys(inputs).forEach(function(k){
      
      if(k!='imageUrl'){
        if($('#'+k).length > 0 ){
          if('validity' in $('#'+k)[0] && !$('#'+k)[0].validity.valid){
              hasErrors = true;
              aux[k] = true
            }
        }
        datos.append(k,inputs[k]);
      }
    })
    
    if(hasErrors){
      setErrors({
        ...errors,
        ...aux
      })
      $('#'+Object.keys(aux))[0].focus();
      return false
    }

    fetch(`${API_URI}/admin/mail/save`,{
      method:'POST',
      body:datos
    })
    .then((res)=>{
      if(res.status != 200){
        alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        return false
      }
      return res.json()
    })
    .then(
      (result) => {
        if(result.result){
          setOpen(false);
          getList();
          setInputs({...inputs,...initInputs});
          setErrors(initErrors)
        }else{
          alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        }
      }
    )
  }

  function cancel(){
      setOpen(false);
      setInputs({...inputs,...initInputs});
      setErrors(initErrors)
  }

  function clickOpen(){
    setOpen(true);
    setInputs({...inputs,...initInputs});
    setErrors(initErrors);
    triggerScroll();
  }


  function ordering(field,dir){
    const obj = {field:field,dir:dir}
    setData({
      ...data,
      ['data']:[],
      ['sorting']:obj
    })
    getList(obj);
  }
  

  function getList(params = null){
    if(!cfg.load){
      getCfg()
    }
    
    const extra = new FormData();
    console.log('cookie',cookies)
    //let srh = (cookies.students_list && cookies.students_list.search)?cookies.students_list.search:null;
    const sort = (cookies.mail_list && cookies.mail_list.sorting)?cookies.mail_list.sorting:null;
    let reset = false;
    //if(params){
      if(params && params.field){
        extra.append('order',params.field);
        extra.append('direc',params.dir)
        reset = true;
      }else if(sort && sort.field){
        extra.append('order',sort.field);
        extra.append('direc',sort.dir)
      }
      // console.log(srh)
      // if(params && params.search && params.search!=""){        
      //   extra.append('search',params.search);
      // }else if((!params || !('search' in params)) && srh){
      //   extra.append('search',srh);
      // }else{
      //   if(!params){
      //     params = {}
      //   }
      //   params.search = srh = null;
      // }
      console.log(cookies.mail_list)
      if(params && params.page){
        extra.append('page',params.page);
      }else if(!reset && cookies.mail_list){        
        extra.append('page',cookies.mail_list.paginate.current);
      }


    fetch(`${API_URI}/admin/mail/list`,{method:'POST',body:extra})
    .then(res => res.json())
    .then(
      (result) => {
        const datos = [];
        result.data.forEach(function(v,k){
          var items = [
            {name:v.name,type:'text'},
            {name:v.event.name,type:'text'},
            
            {name:'',type:'cscheckbox',value:v.id,ini:!v.inactive,legends:['Activo','Inactivo'],callback:toggle},
            {name:"editar",type:'link',to:'',action:()=>{toEdit(v.id)}},
            {name:"preview",type:'link',to:`${API_URI}/admin/mail/preview/`+v.id,ext:true},
          ]
          datos.push(items);
          
        })

        const paginate = {'current':result.current_page,'next':result.next_page_url,'prev':result.prev_page_url,'total':result.last_page};
        setData({
          ...data,
          ['data']:datos,
          ['pagination'] : {'current':result.current_page,'next':result.next_page_url,'prev':result.prev_page_url,'total':result.last_page},
          ['sorting']:(params && params.field)?params:sort
        })
        console.log('entro')
        setCookie('mail_list',{
          'paginate':paginate,
          'sorting':(params && params.field)?{'field':params.field,'dir':params.dir}:sort,
          // 'search':(params && params.search)?params.search:srh,
        })
      })
  }

  


  async function getCfg() {
    const resp = await fetch(
      `${API_URI}/admin/mail/cfg`
    );
    const json = await resp.json();
    setCfg({...json,['load']:true});
  }

  function getTimes(){
    let filter = [];
    if(inputs.event){
      filter =  cfg.events.find(elem => elem.value==inputs.event)['filter'] || [];
      
    }
    //console.log(cfg.times.filter(elem => filter.includes(elem.value)))
    return cfg.times.filter(elem => filter.includes(elem.value));
  }

  
  useEffect(getList,[])
  const refer = React.useRef(null)
  useEffect(()=>{
    if(open){
      triggerScroll();
    }
  },[open])

  function triggerScroll(){
    setTimeout(function(){
      $('#main-body').animate({
          scrollTop:(refer.current.offsetTop)+"px",
      },800)
    },)
  }

  const classes = useStyles();
  return (
    <GridContainer>

      <GridItem xs={12} sm={12} md={12} style={{textAlign:'right',marginTop:'-70px', zIndex:'1300'}}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={5} style={{alignSelf:'center'}}>
            
          </GridItem>
          <GridItem xs={12} sm={12} md={4} style={{alignSelf:'center'}}>
            <Link color="#2b84e0" to='/admin/history'>Historial de Envios</Link>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={5} sm={5} md={5}>
        <h4 style={{paddingLeft:'20px'}}>Templates predeterminados</h4>
      </GridItem>
      <GridItem xs={5} sm={5} md={5} style={{textAlign:'right'}}>
        <h4 style={{color:'#E8833F'}}>Agregar Nuevo <AddCircleOutlineIcon style={{cursor:'pointer'}} onClick={()=>clickOpen()}/></h4>
      </GridItem>
      <GridItem xs={12} sm={12} md={10}>
        <Card>
          <CardBody>
            <Table 
              tableHeaderColor="info"
              tableHead={data.headers}
              tableData={data.data}
              sortered={data.sorting}
              sortCallback={ordering}
              paginate={getList}
              dtaPage = {data.pagination}
            />
          </CardBody>
        </Card>
      </GridItem>
    
    
  
      
      <GridItem xs={12} sm={12} md={11}>
        <Collapse in={open}>
          <div ref={refer}>
            <Card>
              <CardBody>
              <GridContainer>
                  <GridItem xs={12} sm={12} md={11}>
                    <CustomInput
                        labelText="Nombre"
                        id="title"
                        value={inputs.title}
                        onChange={handleChanges}
                        error={errors.title}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required:true,
                          placeholder:'',
                          
                        }}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={11}>
                    <CustomInput
                        labelText="Asunto"
                        id="subject"
                        value={inputs.subject}
                        onChange={handleChanges}
                        error={errors.subject}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required:true,
                          placeholder:'Introduce el asunto del mail',
                        }}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={11}>
                    <CustomTextArea
                      labelText="Texto"
                      id="text"
                      value={inputs.text}
                      onChange={handleChanges}
                      error={errors.text}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required:true,
                        placeholder:'Hola {{$studiante->nombre}}  tu curso está por vencer, dentro de 7 días.',
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4>Condiciones de envío</h4>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} >
                        <CustomSelect
                            options={cfg.events}
                            id="event"
                            value={inputs.event}
                            required={true}
                            error={errors.event}
                            onChange={handleChanges}
                            label="Evento"
                            name="paisSubsc"
                            style={{marginTop:'0px'}}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomSelect
                            options={getTimes()}
                            id="time"
                            value={inputs.time}
                            required={true}
                            error={errors.time}
                            onChange={handleChanges}
                            label="Tiempo"
                            name="paisSubsc"
                            style={{marginTop:'0px'}}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  {(["before","after"].includes(inputs.time))?
                    <GridItem xs={12} sm={12} md={2} style={{marginTop:'38px'}}>
                      <CustomInput
                        labelText="Días"
                        id="days"
                        value={inputs.days}
                        onChange={handleChanges}
                        error={errors.days}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required:true,
                          placeholder:'Días',
                          type:'number'
                        }}
                      />
                      
                    </GridItem>
                    :''
                  }
                  <GridItem xs={12} sm={12} md={(["before","after"].includes(inputs.time))?4:6} style={{marginTop:'38px'}}>
                    <CustomSelect
                        options={cfg.targets}
                        id="target"
                        value={inputs.target}
                        required={true}
                        error={errors.target}
                        onChange={handleChanges}
                        label="Listado de alumnos"
                        name="paisSubsc"

                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={11} style={{textAlign:'right', marginTop:'30px'}}>
                    <Button onClick={cancel} color="gray" >Cancelar</Button>
                    <Button onClick={save} color="info" >Programar</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          
          </div>
        </Collapse>
      </GridItem>
      
  
    </GridContainer>
  );
}
