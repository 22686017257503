import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import routes from "routes.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Titulo1 from "components/Typography/Titulo1.js"
import Paper from '@material-ui/core/Paper';
import SimpleInput from "components/CustomInput/SimpleInput.js";
import CustomInputFile from "components/CustomInput/CustomInputFile.js"
import FormCheckbox from "components/CustomInput/FormCheckbox.js"
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import Container from '@material-ui/core/Container';
import background from "assets/img/background.jpg";
import logo from "assets/img/logo_login.svg";
import {withCookies, useCookies } from 'react-cookie';
import { API_URI } from '../helpers';
let ps;



const useStyles = makeStyles(styles);

export default function Login(props) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['api']);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
//console.log(cookies.lastuser);
  const [inputs,setInputs] = useState({
    user:(cookies.lastuser)?cookies.lastuser:'',
    password:''
  });

  const [remember,setRemember] = useState('lastuser' in cookies);
  const [errors,setErrors]  = useState({
    user:'',
    password:''
  });

  

  function handleChanges(event){
    console.log(event)
    const name = event.target.id;
    setInputs({
        ...inputs,
        [name]: event.target.value,
      });
  }


  function loginFn(event) {
    event.preventDefault()
    const data = new FormData();
    var hasErrors = false;
    var aux = {};
    console.log(inputs)
    Object.keys(inputs).forEach(function(k){
      if($('#'+k).length > 0 ){
        if('validity' in $('#'+k)[0] && !$('#'+k)[0].validity.valid){
            hasErrors = true;
            aux[k] = true
          }
      }
      data.append(k,inputs[k]);
    })
    
    if(hasErrors){
      setErrors({
        ...errors,
        ...aux
      })
      $('#'+Object.keys(aux))[0].focus();
      return false
    }
   
    fetch(`${API_URI}/admin/login`,{
      method:'POST',
      body:data
    })
    .then(res => res.json())
    .then(
      (result) => {
        if(result.result){
          setCookie('profile',result.profile, { path: '/' });
          if(remember){
            setCookie('lastuser',inputs.user, { path: '/' });
          }else{
            removeCookie('lastuser');
          }
          setCookie('api', result.token, { path: '/' });
          if (result.profile == "admin") {
            props.history.push("/admin/sales");
          } else {
            props.history.push("/admin/changePassword");
          }
        }
      })

  }
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  
  return (
    <div className={classes.wrapper} style={{ 
      backgroundImage:`url(${background})`,
      backgroundRepeat:'no-repeat',
      backgroundSize:'cover',
      display: "flex",
    }}>

      <Container maxWidth="xs" style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}>
        <img src={logo}/>
        <Paper style={{
          marginTop:'40px',
          paddingRight:'24px',
          paddingLeft:'24px',
          paddingTop:'40px',
          paddingBottom:'40px',
        }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Titulo1>Login</Titulo1>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                  <SimpleInput
                    id="user"
                    style={{marginTop:'0px'}}
                    value={inputs.user}
                    onChange={handleChanges}
                    error={errors.user}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      placeholder:'Usuario',
                      required:true,
                    }}
                  />       
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              
                  <SimpleInput
                    id="password"
                    value={inputs.password}
                    onChange={handleChanges}
                    error={errors.password}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      placeholder:'Contraseña',
                      required:true,
                      type:'password'
                    }}
                  />       
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <FormCheckbox
                    id='remember'
                    label = 'Recordarme'
                    onChange={()=>{setRemember(!remember)}}
                    value={remember}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Button onClick={loginFn} color="info" >Ingresar</Button>
              
            </GridItem>
          </GridContainer>
        </Paper>
      </Container>

      
      
      
      
    </div>
  );
}
