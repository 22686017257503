import React from 'react';
import { makeStyles, withStyles, fade} from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// core components
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

const BootstrapPicker = withStyles(theme => ({
  root: {
    backgroundColor: '#E0E0E0',
    borderRadius: 4,
    border: '1px solid #ced4da',
    paddingRight:'10px',
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '&:focus': {
      boxShadow: `${fade('#2B84E0', 0.25)} 0 0 0 0.2rem`,
      borderColor: '#2B84E0',
    },

    '&.Mui-error':{
      borderColor:'red',
    },

  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: '#E0E0E0',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&::placeholder':{
      color:'#828282',
      fontStyle:'italic',
      fontFamily:'Rubik'
    }
  },
  },
  label:{
    '&:focus':{
      color:'red'
    },

    'label.Mui-focused': {
      color: 'green !important',
    }
  }
}))(KeyboardDatePicker);

export default function CustomDatePicker(props) {
  const classes = useStyles();
  const {label,id,onChange,value,formControlProps,error, required} = props;
  
  function toValue(strdate){
    return (strdate)?new Date(strdate.replace('-','/')):null
  }

  function handleDateChange(e,v){
    if(e!='Invalid Date'){
      onChange({target:{id:id,value:(e)?e.toISOString().split('T')[0]:null}})
    }
    
  }

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
      error={error && !value}
    >

      <InputLabel shrink htmlFor="id">
        {label}
      </InputLabel>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <BootstrapPicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id={id}
          error = {error && !value}
          autoOk
          value={toValue(value)}
          onChange={handleDateChange}
          inputProps={{
            required : required,
            placeholder:"dd/mm/yyyy"
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
    </MuiPickersUtilsProvider>
    </FormControl>
  );
}