import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import styles from "assets/jss/material-dashboard-react/components/typographyStyle.js";

const useStyles = makeStyles(styles);

export default function Link(props) {
  const classes = useStyles();
  const { children, to, onClick,color, style, loading,loadingMsg,ext } = props;  

  if(to!="" && !ext){
    return (
      <NavLink to={to} >
        <div className={classes.defaultFontStyle + " " + classes.linkText} style={{...style,color:color}}>
          {children}
        </div>
      </NavLink>
    );
  }else if(to!="" && ext){
    return (
      <a href={to} target={"_blank"}>
        <div className={classes.defaultFontStyle + " " + classes.linkText} style={{...style,color:color}}>
          {children}
        </div>
      </a>
    )
  }
  else{
    return (
      

      <span>
        <div onClick={onClick} className={classes.defaultFontStyle + " " + classes.linkText} style={{...style,color:color,display:'inline-block',marginRight:'8px'}}>
        {(loading)?loadingMsg:children}
        </div>
        {loading && <CircularProgress size={24} style={{color:'#E8833F',verticalAlign:'middle'}}/>}
      </span>
    )
  }
  
}

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string
};
