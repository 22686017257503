import React, {useState, useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Collapse from '@material-ui/core/Collapse';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Titulo1 from "components/Typography/Titulo1";
import Button from "components/CustomButtons/Button.js";
import AlertDialog from "components/Dialogs/AlertDialog.js"
import TableDialog from "components/Dialogs/TableDialog.js"
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomTextArea from "components/CustomInput/CustomTextArea.js"
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AsyncAutocomplete from 'components/CustomInput/AsyncAutocomplete.js'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { List, ListItem, ListItemText, ListItemSecondaryAction, Divider } from "@material-ui/core";

import { API_URI } from '../../helpers';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};



const useStyles = makeStyles(styles);



export default function ListCategories() {
  const [data, setData] =  useState({
    headers:[{name:"",type:"checkbox"}, {name:"Nombre",type:"text",sortable:'name'},{name:"Fecha de creación",type:"text",sortable:'created'},{name:"Estado",type:"text"},{name:"Cursos",type:"text",width:'250px'},{name:"  ",type:"text"},{name:"  ",type:"text"},{name:"  ",type:"text"}],
    data:[],
    sorting:{},
    pagination:null
  });
  const [open, setOpen] =  useState(false);
  const [openCourses, setOpenCourses] = useState({open:false,id:0})
  const [courses, setCourses] =useState([])

  const [inpCourse, setInpCourse] = useState({})

  const [inputs,setInputs] = useState({id:false,categoryName:'',categoryDescription:'',courses:[]});

  function handleNew(){
    setOpen(!open)
  }

  function handleCategoryName(event){
    const name = event.target.id;
    //inputs[name]=event.target.value;
    setInputs({
      ...inputs,
      [name]:event.target.value
    })
  }

  function toggleCategory(data){
    const datos = new FormData();
    datos.append('id',data.value);
    datos.append('value',data.stat);    
    fetch(`${API_URI}/admin/category/toggle`,{
      method:'POST',
      body:datos
    })
    .then(res => res.json())
    .then(
      (result) => {
        
      }
    )
  }

  function deleteCategory(id){
    const check = window.confirm('Esta seguro que desea eliminar la categoría?')
    console.log(check)
    if(check){
      const datos = new FormData();
      datos.append('id',id);   
      fetch(`${API_URI}/admin/category/delete`,{
        method:'POST',
        body:datos
      })
      .then(res => res.json())
      .then(
        (result) => {
          getList()
        }
      ).catch(function(error) {
        alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
      });
    }
    
  }

  function saveCategory(event){
    event.preventDefault()
    const datos = new FormData();
    datos.append('id',inputs.id)
    datos.append('name',inputs.categoryName)
    datos.append('description',inputs.categoryDescription)
    inputs.courses.forEach(element => {
      
      datos.append(element.source+'[]',parseInt(element.id))
    });
    fetch(`${API_URI}/admin/category/save`,{
      method:'POST',
      body:datos
    })
    .then((res)=>{
      if(res.status != 200){
        alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        return false
      }
      return res.json()
    })
    .then(
      (result) => {
        if(result.result){
          setOpen(!open);
          getList();
          setInputs({id:false,categoryName:'',categoryDescription:'',courses:[]});
        }
      }
    )
  }

  let update = true

  function ordering(field,dir){
    const obj = {field:field,dir:dir}
    setData({
      ...data,
      ['data']:[],
      ['sorting']:obj
    })
    getList(obj);
  }
  

  function getList(params = null){
    const extra = new FormData();
    const sort = (data.sorting)?JSON.parse(JSON.stringify(data.sorting)):null;
    if(params){
      if(params.field){
        extra.append('order',params.field);
        extra.append('direc',params.dir);
      }else if(sort && sort.field){
        extra.append('order',sort.field);
        extra.append('direc',sort.dir)
      }

      if(params.page){
        extra.append('page',params.page);
      }else{
        extra.append('page',data.pagination.current);
      }
      
    }
    fetch(`${API_URI}/admin/categories`,{method:'POST',body:extra})
    .then(res => res.json())
    .then(
      (result) => {
        const datos = [];
        result.data.forEach(function(v,k){
          var items = [
            {name:"",type:'checkbox'},
            {name:v.name,type:'text'},
            {name:v.created,type:'text'},
            {name:'',type:'cscheckbox',value:v.id,ini:!v.inactive,legends:['Activo','Inactivo'],callback:toggleCategory},
            {name:v.courses,type:'text'},
            {name:"Añadir / quitar cursos",type:'link',to:'',action:()=>{getSetCourses(v.id);setOpenCourses({open:true,id:v.id})}},
            {name:"editar",type:'link',to:'',action:()=>{toEdit(v.id);}},
            {name:"",type:'delete',callback:deleteCategory,valret:v.id},
          ]
          datos.push(items);
          
        })
        setData({
          ...data,
          ['data']:datos,
          ['pagination'] : {'current':result.current_page,'next':result.next_page_url,'prev':result.prev_page_url,'total':result.last_page},
          ['sorting']:(params && params.field)?{'field':params.field,'dir':params.dir}:sort
        })
      })
  }

  
  async function getSetCourses(id){
    
    const result = await fetch(`${API_URI}/admin/categories/setCourses/`+id);
    const json = await result.json()
    setCourses(json)
  }

  async function addCourse(e,v,t){
    console.log(e)
    if(v!=null){
      const res = await fetch(`${API_URI}/admin/categories/`+openCourses.id+"/addCourse/"+v.id+"/"+v.source);
      if(res.status != 200){
        alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        return false
      }
      const json = await res.json()
      if(json.result){

        getSetCourses(openCourses.id)
        setInpCourse({})
      }else{
        alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
      }
    }
    
  }

  async function reorderCourse(id,dir){
    const data = new FormData();

    data.append('category',openCourses.id);
    data.append('course',id);
    data.append('dir',dir);
    const res = await fetch(`${API_URI}/admin/categories/orderCourse`,{method:'POST',body:data});
    if(res.status != 200){
      alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
      return false
    }
    const json = await res.json();

    if(json.result){
      getSetCourses(openCourses.id)
    }else{
      alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
    }
  }

  async function delCourse(id,source){
    
    if(id!=null){
      const res = await fetch(`${API_URI}/admin/categories/`+openCourses.id+"/delCourse/"+id+"/"+source);
      if(res.status != 200){
        alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        return false
      }
      const json = await res.json()
      if(json.result){
        getSetCourses(openCourses.id)
      }else{
        alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
      }
    }
    
  }

  async function toEdit(id){
    const res = await fetch(`${API_URI}/admin/category/edit/`+id);
    if(res.status != 200){
      alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
      return false
    }
    const json = await res.json()
    setInputs({id:json.id,categoryName:json.name,categoryDescription:json.description,courses:json.courses})
    setOpen(true)
  }

  function clearForm(){
    setInputs({id:false,categoryName:'',categoryDescription:'',courses:[]})
  }
  useEffect(getList,[update])
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={4} style={{textAlign:'right',marginTop:'-90px', zIndex:'1040'}}>
          <Button color="white" onClick={handleNew} style={{zIndex:'1040'}}>Añadir nuevo</Button>
        </GridItem>
      </GridItem>
      
      <GridItem xs={12} sm={12} md={10}>
        <Collapse in={open} onExited={clearForm}>
          <Card>
            <CardBody>
             <GridContainer>
                <GridItem xs={12} sm={12} md={7}>
                  <CustomInput
                      labelText="Nueva categoría"
                      id="categoryName"
                      value={inputs.categoryName}
                      onChange={handleCategoryName}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder:'Nombre'
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  &nbsp;
                </GridItem>
                <GridItem xs={12} sm={12} md={4} style={{alignSelf:'flex-end', marginBottom:'10px'}}>
                  <Button onClick={saveCategory} color="info" >{(inputs.id)?"ACTUALIZAR":"AGREGAR"} categoría</Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomTextArea
                    labelText="Descripción"
                    id="categoryDescription"
                    value={inputs.categoryDescription}
                    onChange={handleCategoryName}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      placeholder:'Agregar descripción'
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <AsyncAutocomplete 
                      url={`${API_URI}/admin/categories/candidateCourses`}
                      id="courses"
                      label="Agregar cursos"
                      asyncProps={{
                        multiple:'multiple',
                        filterSelectedOptions:true
                      }}
                      val={inputs.courses}
                      onChange={(e,v)=>{
                        setInputs({
                          ...inputs,
                          ['courses']:v
                        })
                      }}
                    />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Collapse>
      </GridItem>
      
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <Table
              tableHeaderColor="info"
              tableHead={data.headers}
              tableData={data.data}
              sortered={data.sorting}
              sortCallback={ordering}
              paginate={getList}
              dtaPage = {data.pagination}
            />
          </CardBody>
        </Card>
      </GridItem>
     <TableDialog
      open={openCourses.open}  
      onClose={
        ()=>{
          setOpenCourses({open:false,id:0})
          getList();
        }
      }
     >
       <div style={{width:'450px'}}>
        { <AsyncAutocomplete 
          url={`${API_URI}/admin/categories/candidateCourses/`+openCourses.id}
          val={inpCourse}
          onChange={addCourse}/> }
        <List >
          {courses.map((value, k) => {
            //const labelId = `checkbox-list-secondary-label-${value}`;
            return (
              <div>
                <ListItem key={value.id} button style={{paddingTop:'1em', paddingBottom:'1em'}}>
                
                  <ListItemText id={'course'+value.id} primary={value.name} secondary={value.source}/>
                  <ListItemSecondaryAction>
                    <GridContainer>
                      {
                        (courses.length>1)?
                        <GridItem  xs={6} sm={6} md={6}>
                          {(k!=0)?
                            <div style={{height:'20px', paddingTop:'5px', paddingBottom:'5px'}}>
                              <ArrowDropUpIcon onClick={()=>{reorderCourse(value.id,'up')}} style={{cursor:'pointer'}}/>
                            </div>:''
                          }
                          
                          {(k!=courses.length-1)?
                          <div style={{height:'20px', paddingTop:'5px', paddingBottom:'5px'}}>
                            <ArrowDropDownIcon onClick={()=>{reorderCourse(value.id,'down')}} style={{cursor:'pointer'}}/>
                          </div>:''
                        }
                          
                        </GridItem>:''
                      }
                      
                      <GridItem  xs={6} sm={6} md={6}>
                        <DeleteOutlinedIcon onClick={()=>{delCourse(value.id,value.source)}} style={{height:'100%', alignItems:'center',color:'#E8833F',cursor:'pointer'}} />
                      </GridItem>
                    </GridContainer>                                                          
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider/>
              </div>
            );
          })}
        </List>
       </div>
     </TableDialog>
    </GridContainer>
  );
}
