import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    formControl: {
      width: '100%',
      minWidth: 120,
      marginTop:'38px',
    },
    root:{
        color:'yellow'
     
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

    fieldset:{
      borderColor:'red'
    }
  }));
  

export default function CustomSelect(props){

    const {options, name, id, label, value, onChange, error,required,style} = props;
    const classes = useStyles();
    const [state, setState] = React.useState({
        value: '',
        name: 'hai',
      });
    
  
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    
    React.useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
  
    
    return (
        <div>
            <FormControl variant="outlined" className={classes.formControl} error={error&&value==""} style={style}>
                <InputLabel ref={inputLabel} htmlFor={id}>
                    {label}
                </InputLabel>
                <Select
                native
                value={value}
                id={id}
                onChange={onChange}
                labelWidth={labelWidth}
                inputProps={{
                    name: {name},
                    id: id,
                    required:required
                }}
                >
                  <option value=""></option>
                  {options.map((v,k)=>{
                    return (
                      <option value={v.value} >{v.display}</option>
                    )
                  })}
                </Select>
            </FormControl>
        </div>
    )
}