import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Link from "components/Typography/Link.js";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CustomCheckbox from "components/CustomInput/CustomCheckbox.js"
import Collapse from '@material-ui/core/Collapse';

import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles(styles);

export default function TableExpandable(props) {

  const classes = useStyles();
    const {items, width, total_rows, extras, config} = props;

    //console.log('extrs',extras)

    const [open,setOpen] = React.useState(false);
    return (
        <Table width={'100%'}>
            <TableBody>
                <TableRow>
                    <TableCell align="left" style={{width:'24px',cursor:'pointer'}} onClick={()=>{setOpen(!open)}} >
                        {open? <ExpandLessOutlinedIcon color="info"/>:<ExpandMoreOutlinedIcon color="info"/>}
                    </TableCell>
                    
                    {items.map((prop, key) => {
                
                    return (
                        <TableCell align="left" className={classes.tableCell} key={key} width={config[key]} style={{minWidth:config[key]}}>
                        {
                        (prop.type==='delete')?
                            <div style={{cursor:'pointer'}} onClick={()=>{prop.callback(prop.valret)}}>
                            <DeleteOutlinedIcon style={{color:'#E8833F'}}/>
                            </div>
                            

                            :(prop.type == "checkbox")?
                            
                            <Checkbox
                                color="info"
                                />
                            :(prop.type == 'cscheckbox')?
                            
                            <CustomCheckbox
                            value={prop.value}
                            legends = {prop.legends || ['Activo','Inactivo']}
                            label = {prop.name}
                            onChange={prop.callback}
                            init={prop.ini}
                            />
                            :""}
                        
                            {prop.type == "link"?(prop.to!='')?
                            <Link to={prop.to}>{prop.name}</Link>:
                            <Link to={prop.to} onClick={prop.action}>{prop.name}</Link>
                            :<span style={{width:config[key],display:'block',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>{prop.name}</span>}
                        </TableCell>
                    );
                    })}
                </TableRow>
                <TableRow>
                    <TableCell colSpan={total_rows+1} style={{padding:'0px',paddingLeft:'40px'}}>
                        <Collapse in={open} style={{width:'69vw',overflowX:'auto'}}>
                            <GridContainer style={{width:(extras.length*350)+'px'}}>
                                {extras.map((val,key)=>{
                                    return (
                                        <GridItem style={{float:'left',padding:'25px',width:'350px'}}>
                                            <p style={{fontFamily:'Rubik',fontSize:'16px',lineHeight:'.1em',letterSpacing:'-.2em',fontWeight:500, marginBottom:'12px;'}}>{val.name}</p>
                                            <p style={{fontFamily:'Rubik',fontSize:'13px',lineHeight:'.1em',letterSpacing:'-.2em'}}>- {val.complete_lessons} clases finalizadas, {val.percent_complete}% completado del curso.</p>
                                    <p style={{fontFamily:'Rubik',fontSize:'13px',lineHeight:'.1em',letterSpacing:'-.2em'}}>- Última clase tomada el {(val.last)?val.last.updated_at:'ninguna'}</p>
                                        </GridItem>  
                                    )
                                })}
                                  
                            </GridContainer>

                            
                            
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
        
    
    )
}