import React, { useState, useEffect } from "react";
import $ from "jquery";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ProgressButton from "components/CustomButtons/ProgressButton.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Titulo1 from "components/Typography/Titulo1.js"
import CustomInputFile from "components/CustomInput/CustomInputFile.js"
import CustomSelect from "components/CustomInput/CustomSelect.js"
import TableDialog from "components/Dialogs/TableDialog.js"
import TableStudents from "views/Cursos/TableStudents.js";
import CustomTextArea from "components/CustomInput/CustomTextArea.js"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import FormCheckbox from "components/CustomInput/FormCheckbox.js"

import { API_URI } from '../../helpers';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function FormSongs(props) {
  const classes = useStyles();
  const [loading,setLoading] = useState(false);
  const [inputs,setInputs] = useState({
    songName:"",
    songDescription:"",
    songPerformer:'',
    songCover:'',
    songVideo:'',
    songId:false,
    songLevel:'',
    songDuration:'',
    songIsFree:false,
    rewardImage:null,
    RewardDescription:'',
    songBack:'',
    songTab:'',
    RewardTitle:'',
    songGuitar:'acustica',
    rewardId:false
  })

  const errorsInit = {
    songName:false,
    songDescription:false,
    songPerformer:false,
    songCover:false,
    songVidIntro:false,
    songVideo:false,
    songLevel:false,
    songDuration:false
  }

  const [errors,setErrors] = useState(errorsInit)
  const [levels,setLevels] = useState([])
  const [listStudent,setListStudent] = useState({open:false,courseId:false})

  async function getLevels(){
    const response = await fetch(`${API_URI}/admin/cfg/levels`);
    const json = await response.json();
    setLevels(json);
  }


  useEffect(()=>{
    getLevels()

    if(inputs.toNew){
      setTimeout(function(){
        $('#main-body').animate({
            scrollTop:myRef.current.offsetTop+"px",
        },800)
      },)
      inputs.toNew = false;
    }
    if(props.match.params.songId){
      fetch(`${API_URI}/admin/song/get/`+parseInt(props.match.params.songId))
      .then(res => res.json())
      .then(
        (result) => {
          
            const aux = {
              songName:result.name,
              songVideo:result.video,
              songCover:result.cover,
              songDescription:result.description,
              songPerformer:result.performer_name,
              songDuration:result.duration,
              songLevel:result.level_id,
              songGuitar:result.guitar,
              songId:result.id,
              songIsFree:result.free,
              songBack:result.backtrack,
              songTab:result.tablatura,
              rewardId:(result.reward)?result.reward.id:false,
              RewardDescription:(result.reward)?result.reward.description:'',
              RewardTitle:(result.reward)?result.reward.title:'',
              rewardImage:(result.reward)?result.reward.image:null,
            }
            setInputs(aux);
            setErrors(errorsInit);
      }).catch(function(error) {
        alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
      });
    }
    
  },[])

  function handleSubmit(event) {
    setLoading(true);
    event.preventDefault()
    const data = new FormData();
    if(event.currentTarget.dataset.extra == 'publish'){
      data.append('publish',true);
    }
    var hasErrors = false;
    var aux = {};
    Object.keys(inputs).forEach(function(k){
      
      if(k!='imageUrl'){
        if($('#'+k).length > 0 ){
          if('validity' in $('#'+k)[0] && !$('#'+k)[0].validity.valid){
              hasErrors = true;
              aux[k] = true
            }
        }
        data.append(k,inputs[k]);
      }
    })
    
    if(hasErrors){
      setLoading(false);
      setErrors({
        ...errors,
        ...aux
      })
      $('#'+Object.keys(aux))[0].focus();
      return false
    }
   
    fetch(`${API_URI}/admin/song/save`,{
      method:'POST',
      body:data
    })
    .then((res)=>{
      if(res.status != 200){
        alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        return false
      }
      return res.json()
    })
    .then(
      (result) => {
        if(result.result){
          props.history.push("/admin/cursos/Canciones");
        }else{
          if(result.reason && result.reason=='repeat'){
            alert('Ya existe una canción con este nombre.')
          }else{
            alert("No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
          }
          
        }
        setLoading(false);
      })

  }

  function cancelEdit(){
    props.history.push("/admin/cursos/Canciones");
  }

  
  function handleChanges(event){
    const name = event.target.id;
    setInputs({
        ...inputs,
        [name]: (event.target.type == "checkbox")?(event.target.value=='true'):event.target.value,
      });
  }

  function changeBackT(elem){
    setInputs({
        ...inputs,
        ['songBack']: elem
      });
  }

  function changeTab(elem){
      setInputs({
          ...inputs,
          ['songTab']: elem
        });
  }

  function changeImage(elem){
    inputs['songCover'] = elem
    setInputs(inputs)
  }

  function changeRwardImg(elem){
    inputs['rewardImage'] = elem
    setInputs({
      ...inputs,
      ['rewardImage']: elem
    });
  }

 
 
  const myRef = React.useRef(null)

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={7} style={{marginTop:'-90px', zIndex:'1040'}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Titulo1>Agregar nueva Canción.</Titulo1>
            </GridItem>

            <GridItem xs={12} sm={12} md={6} style={{textAlign:'right'}}>
              
              <Button color="gray" onClick={cancelEdit}>Cancelar</Button>
              <ProgressButton color="info" onClick={handleSubmit} loading={loading} loadingMsg="Guardar">
                  Guardar
              </ProgressButton>
            </GridItem>
          </GridContainer>
          
        </GridItem>
          
        <GridItem xs={12} sm={12} md={8}>
          <form>
            
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    labelText="Nombre de la canción"
                    id="songName"
                    key="songName"
                    formControlProps={{
                      fullWidth: true,
                      required:true,
                    }}
                    error={errors.songName}
                    value={inputs.songName}
                    onChange={handleChanges}
                    inputProps={{
                      key:"songName",
                      required:true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    labelText="Interprete"
                    id="songPerformer"
                    formControlProps={{
                      fullWidth: true,
                      required:true,
                    }}

                    value={inputs.songPerformer}
                    onChange={handleChanges}
                    error={errors.songPerformer}
                    inputProps={{
                      placeholder: 'Nombre Interprete',
                      required:true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  {/* <CustomInput
                      labelText="Descripción"
                      id="songDescription"
                      formControlProps={{
                        fullWidth: true,
                        required:true,
                      }}

                      value={inputs.songDescription}
                      onChange={handleChanges}
                      error={errors.songDescription}
                      inputProps={{
                        placeholder: 'Descripción',
                        required:true,
                      }}
                    /> */}
                     <CustomTextArea
                        labelText="Descripción"
                        id="songDescription"
                        value={inputs.songDescription}
                        onChange={handleChanges}
                        error={errors.songDescription}
                        formControlProps={{
                          fullWidth: true,
                          required:true
                        }}
                        inputProps={{
                          placeholder:'Agregar descripción',
                          required:true,
                        }}
                      />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={10}>
                  <CustomInput
                      labelText="Video introductorio"
                      id="songVidIntro"
                      formControlProps={{
                        fullWidth: true,
                        required:true,
                      }}

                      value={inputs.songVidIntro}
                      onChange={handleChanges}
                      error={errors.songVidIntro}
                      inputProps={{
                        placeholder: 'Cargar URL del video ',
                        required:true,
                      }}
                    />
                </GridItem> */}
                <GridItem xs={12} sm={12} md={10}>
                  <CustomInput
                      labelText="Video Clase"
                      id="songVideo"
                      formControlProps={{
                        fullWidth: true,
                        required:true,
                      }}

                      value={inputs.songVideo}
                      onChange={handleChanges}
                      error={errors.songVideo}
                      inputProps={{
                        placeholder: 'Cargar URL del video ',
                        required:true,
                      }}
                    />
                </GridItem>
                
                <GridItem xs={12} sm={12} md={6}>
                                    
                  <CustomInputFile
                      onChange={changeBackT}
                      accept="audio/*"
                      value={inputs.songBack}
                      label="Backtrack"
                  />  
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <CustomInputFile
                        onChange={changeTab}
                        accept="image/*,.pdf"
                        value={inputs.songTab}
                        label="Tablatura"
                    />  
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <FormCheckbox
                      id='songIsFree'
                      label = 'canción libre'
                      onChange={handleChanges}
                      value={inputs.songIsFree}
                  />
              </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{marginTop:'24px'}}>
                  <CustomInputFile
                    value={inputs.rewardImage}
                    label="Subir imagen."
                    onChange={changeRwardImg}
                    typeBtn='link'
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  <CustomInput
                   labelText="Título del Premio"
                   id="RewardTitle"
                   value={inputs.RewardTitle}
                   onChange={handleChanges}
                   placeholder=""
                   formControlProps={{
                     fullWidth: true
                   }}
                   inputProps={{
                     name:"lessons",
                     placeholder:'Agregar un Titulo'
                   }}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  <CustomTextArea
                   labelText="Descripción del premio"
                   id="RewardDescription"
                   value={inputs.RewardDescription}
                   onChange={handleChanges}
                   placeholder=""
                   formControlProps={{
                     fullWidth: true
                   }}
                   inputProps={{
                     placeholder:'Agregar descripción'
                   }}/>
                </GridItem>
                
              </GridContainer>
            </CardBody>
          </Card>
        </form>
        </GridItem>

       
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardBody profile>
              <h4>Imagen destacada</h4>
              <CustomInputFile
                value={inputs.songCover}
                onChange={changeImage}
                typeBtn='link'
                preview={true}
              />              
            </CardBody>
          </Card>
          <Paper style={{marginTop:'40px'}}>
            <Tabs
              value={inputs.songGuitar}
              indicatorColor="primary"
              textColor="primary"
              id="songGuitar"
              onChange={(eleme,v)=>{
                handleChanges({target:{id:'songGuitar',value:v}})
              }}
              aria-label="Tipo de Guitarra"
            >
              <Tab value="acustica" label="Acustica" />
              <Tab value="electrica" label="Electrica" />
            </Tabs>
                
          </Paper>
        
          <CustomSelect
            options={levels}
            label="Dificultad"
            name="CourseLevelId"
            id="songLevel"
            onChange={handleChanges}
            value={inputs.songLevel}
            error={errors.songLevel}
            required={true}
          />

          <div style={{marginTop:'48px', textAlign:'center'}} >
            <Button color="info" data-extra="publish" onClick={handleSubmit}>Publicar canción</Button>
          </div>
          {(inputs.songId)?
          <div style={{marginTop:'96px', textAlign:'center'}} >
            <Button color="info" data-extra="publish" onClick={()=>{setListStudent({open:true,courseId:inputs.songId})}}>Ver listado de alumnos</Button>
          </div>:""}
          
        </GridItem>
        
      </GridContainer>
      <TableDialog open={listStudent.open} onClose={()=>{setListStudent({open:false,courseId:false})}} width="940px" title='Alumnos en el curso'>
         <div >
            <TableStudents
              tableHeaderColor="info"
              courseID={listStudent.courseId}
              source='song'
            />
         </div>
       </TableDialog>
    </div>
  );
}
