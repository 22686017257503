import React,{useState} from "react";
import Checkbox from '@material-ui/core/Checkbox';


export default function CustomCheckbox(props) {
    const {color, onChange, disabled, value, label, legends, id, init, reset, resetable,source} = props;

    
    
    const [realVal, setRealVal] = useState(init)
    function fnChange(e){
        const target = JSON.parse(e.target.value) || e.target.value;
        const value = (source)?{source:source,id:target}:target;
        
        onChange({stat:e.target.checked,value:value})
        setRealVal(e.target.checked)
    }
    
    React.useEffect(()=>{
        
        if(resetable != undefined){
            setRealVal(false)
        }
        
    },[reset])

    React.useEffect(()=>{
        
        //if(resetable != undefined){
            setRealVal(init)
        //}
        
    },[init])
    return (
        <div>
            <Checkbox
                id={id || ''}
                color={color || 'info'}
                onChange={fnChange}
                checked={realVal}
                value={value}
                disabled={disabled}
            />
            {(legends)?(realVal)?legends[0]:legends[1]:label}
        </div>
       
    )
}