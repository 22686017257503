import React, {useState, useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

import Button from "components/CustomButtons/Button.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Link from "components/Typography/Link.js";
import Searchbar from "components/Navbars/Searchbar";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { API_URI } from '../../helpers';


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};





const useStyles = makeStyles(styles);

export default function MailHistory() {

  const [anchor,setAnchor] = React.useState(null);

 
  const [tableData, setTableData] =  useState({
    header:[
      {name:"Template",type:"text",sortable:'template.name'},
      {name:"Target",type:"text",sortable:'template.target'},
      {name:"Email Destinatario",type:"text",sortable:'student.mail'},
      {name:"fecha",type:"text",sortable:'created_at'},
     
    ],
    data:[],
    sorting:{},
    pagination:null,
    search:null
  });
  const [toDelete,setToDelete] = useState([]);
  const [resetTable,setResetTable] = useState(false);

  function reset(){
    setResetTable(!resetTable);
  }

  function getList(params = null){
    //console.log()
    const sort = (tableData.sorting)?JSON.parse(JSON.stringify(tableData.sorting)):null;
    let srh = (tableData.search)?JSON.parse(JSON.stringify(tableData.search)):null;
    const extra = new FormData();
   console.log(params,sort)
    if(params){
      if(params.field){
        
        extra.append('order',params.field);
        extra.append('direc',params.dir)
      }else if(sort && sort.field){
        extra.append('order',sort.field);
        extra.append('direc',sort.dir)
      }

      if(params.search && params.search!=""){        
        extra.append('search',params.search);
      }else if(!('search' in params) && srh){
        extra.append('search',srh);
      }else{
        params.search = srh = null;
      }

      if(params.page){
        extra.append('page',params.page);
      }else if(!params.field && !params.search){
        extra.append('page',tableData.pagination.current);
      }

      
    }
    fetch(`${API_URI}/admin/mail/history`,{method:'POST',body:extra})
    .then(res => res.json())
    .then(
      (result) => {
        const data = [];
        result.data.forEach(function(v,k){
          var items = [
            // {name:"",type:'checkbox'},
            
            {name:v.template.name,type:'text'},
            {name:v.template.target,type:'text'},
            {name:(v.student)?v.student.mail:'sin mail',type:'text'},
            {name:v.created_at,type:'text'},
            
          ]
          data.push(items);
        })
        setTableData({
          ...tableData,
          ['data']:data,
          ['pagination'] : {'current':result.current_page,'next':result.next_page_url,'prev':result.prev_page_url,'total':result.last_page},
          ['sorting']:(params && params.field)?{'field':params.field,'dir':params.dir}:sort,
          ['search'] : (params && params.search)?params.search:srh,
        })
      })
  }

  useEffect(getList,[])

  function ordering(field,dir){
    const obj = {field:field,dir:dir}
    setTableData({
      ...tableData,
      ['data']:[],
      ['sorting']:{field:field,dir:dir}
    })
    getList(obj);
  }
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}  style={{textAlign:'right',marginTop:'-90px'}}>
        <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} style={{zIndex:'2040'}}>
          <Searchbar target={getList}/>
        </GridItem>
        </GridContainer>
      </GridItem>
      
      <GridItem xs={12} sm={12} md={12}>
        <Card>
         
          <CardBody>
            <Table
              tableHeaderColor="info"
              tableHead={tableData.header}
              tableData={tableData.data}
              sortered={tableData.sorting}
              sortCallback={ordering}
              paginate={getList}
              dtaPage = {tableData.pagination}
            />
          </CardBody>
        </Card>
      </GridItem>
     
    </GridContainer>
  );
}
