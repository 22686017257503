/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, HashRouter, BrowserRouter } from "react-router-dom";
import { CookiesProvider, Cookies } from 'react-cookie';

// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";
import Login from "layouts/login.js";

import "assets/css/material-dashboard-react.css?v=1.8.0";

const hist = createBrowserHistory();
const cookie = new Cookies();
//console.log('la cookie',cookie.get('api'))
ReactDOM.render(
  <CookiesProvider>
    <HashRouter history={hist}>

      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/rtl" component={RTL} />
        <Route path="/login" component={Login} />
        {cookie.get('api')?
         <Redirect from="/" to="/admin/sales" />:
         <Redirect to="/login" />
        }
       
      </Switch>
    </HashRouter>
  </CookiesProvider>,
  document.getElementById("root")
);

export const API_URI = 'https://api.tusclasesdeguitarra.com';