import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import { API_URI, formatDate } from '../../helpers';


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function ChangePassword() {

  const initInputs = {
    mail:'',    
    password:'',
  }

  const initErrors = {
    mail:'',
    password:''
  }

  const [submitResult, setSubmitResult] = useState(null);
  const [inputs,setInputs] = useState({...initInputs});
  const [errors,setErrors] = useState(initErrors);

  function handleChanges(event){
    const name = event.target.id;
    setInputs({
        ...inputs,
        [name]: event.target.value,
      });
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData();
    const aux = {};

    data.append('mail', inputs.mail);
    data.append('password', inputs.password);

    fetch(`${API_URI}/admin/changePassword`, {
      method: "POST",
      body: data
    })
      .then((res)=>{
        if(res.status != 200){
          alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
          return false
        }
        return res.json()
      })
      .then(result => {
        console.log(result);
        setSubmitResult(result);
      });
  }

  const classes = useStyles();
  return (
    <GridContainer>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Mail"
                id="mail"
                value={inputs.mail}
                error={errors.mail}
                onChange={handleChanges}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Contraseña"
                id="password"
                value={inputs.password}
                error={errors.password}
                onChange={handleChanges}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4} style={{ marginTop: "46px" }}>
              <Button color="info" onClick={handleSubmit}>Modificar</Button>
            </GridItem>

          </GridContainer>
          { submitResult ? <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              { !submitResult.clientExists ? "No existe cliente con el mail indicado." : ''}
              { submitResult.clientExists && submitResult.updated == 1 ? "La contraseña se ha modificado exitosamente." : ''}
              { submitResult.clientExists && submitResult.updated == 0 ? "No se ha podido modificar la contraseña." : ''}
            </GridItem>
          </GridContainer> : ''}
        </CardBody>
      </Card>
    </GridContainer>
   
  );
}
