import React, { useState, useEffect, Fragment } from "react";
import $ from "jquery";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDatePicker from "components/CustomInput/CustomDatePicker.js";
import CustomSelect from "components/CustomInput/CustomSelect";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import Table from "components/Table/Table.js";
import Titulo1 from "components/Typography/Titulo1.js";
import Collapse from "@material-ui/core/Collapse";

import { API_URI, formatDate } from '../../helpers';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function FormStudents(props) {
  
  const [openSusc, setOpenSusc] = useState(true);

  const [cfg, setCfg] = useState({
    countries: [],
    plans: [],
    methods: [],
    status: [],
    prices:{}
  });

  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData();
    const aux = {};
    Object.keys(inputs).forEach(function(k) {
      if ($("#" + k).length > 0) {
        if ("validity" in $("#" + k)[0] && !$("#" + k)[0].validity.valid) {
          aux[k] = true;
        }
      }
      data.append(k, inputs[k]);
    });
    
    if (Object.keys(aux).length > 0) {
      setErrors({
        ...errors,
        ...aux
      });
      $("#" + Object.keys(aux))[0].focus();
      return false;
    }

    //https://18.215.96.2/api
    fetch(`${API_URI}/admin/student/save`, {
      method: "POST",
      body: data
    })
      .then((res)=>{
        if(res.status != 200){
          alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
          return false
        }
        return res.json()
      })
      .then(result => {
        if (result.result) {
          if (!inputs.studentId) {
            setInpunts({
              ...inputs,
              ["studentId"]: result.insert_id
            });
          } else {
            props.history.push("/admin/Students");
          }
        }else{
          alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        }
      });
  }

  const [initSuscript, setInitSuscript] = useState({
    studentSuscPlan: "",
    studentSubsStart: null,
    studentSubsEnd: null,
    studentSuscMethod: "",
    studentSuscCountry: "",
  });

  const [inputs, setInpunts] = useState({
    studentName: "",
    studentLastname: "",
    studentEmail: "",
    studentCountry: "",
    studentState:"",
    studentId: false,
  });

  const [inputSuscription, setInputSucription] = useState(initSuscript);

  const errorsInit = {
    studentName: false,
    studentLastname: false,
    studentEmail: false,
    studentCountry: false,
    studentId: false,
    studentState:false,
    studentSuscPlan: false,
    studentSubsStart: false,
    studentSubsEnd: false,
    studentSuscMethod: false,
    studentSuscCountry: false,
  };

  const [errors, setErrors] = useState(errorsInit);

  useEffect(() => {
    if (props.match.params.studentId) {
      fetch(
        `${API_URI}/admin/student/get/` +
          parseInt(props.match.params.studentId)
      )
        .then(res => res.json())
        .then(result => {
          const data = {
            studentName: result.name,
            studentLastname: result.lastname,
            studentEmail: result.mail,
            studentCountry: result.country_id,
            studentState: result.state,
            studentId: result.id
          };

          setInpunts(data);
          getSuscriptions(result.id);

          initSuscript.studentSuscCountry = result.country_id;
          setInitSuscript(initSuscript);

          inputSuscription.studentSuscCountry = result.country_id;
          setInputSucription(inputSuscription);
        });
    }
    getCfg();
  }, [props.match.params.studentId]);

  async function getCfg() {
    const resp = await fetch(
      `${API_URI}/admin/student/cfg`
    );
    const json = await resp.json();

    setCfg(json);
    
    var endDate = new Date();
    endDate.setDate(endDate.getDate() + json.plans[0].duration);

    const data = {
      studentSuscPlan: json.plans[0].value,
      studentSubsStart: formatDate(new Date(Date.now())),
      studentSubsEnd: formatDate(endDate),
      studentSuscMethod: json.methods[0].value,
      studentSuscCountry: inputSuscription.studentSuscCountry
    };

    setInitSuscript(data);
    setInputSucription(data);
  }

  function submithWithSubs(event) {
    event.preventDefault();
    const data = new FormData();
    const aux = {};
    Object.keys(inputSuscription).forEach(function(k) {
      if ($("#" + k).length > 0) {
        if ("validity" in $("#" + k)[0] && !$("#" + k)[0].validity.valid) {
          aux[k] = true;
        }
      }
      data.append(k, inputSuscription[k]);
    });
    data.append('studentId', inputs.studentId);
    if (Object.keys(aux).length > 0) {
      setErrors({
        ...errors,
        ...aux
      });
      $("#" + Object.keys(aux))[0].focus();
      return false;
    }

    fetch(`${API_URI}/admin/suscriptions/save`, {
      method: "POST",
      body: data
    })
      .then((res)=>{
        if(res.status != 200){
          alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
          return false
        }
        return res.json()
      })
      .then(result => {
        if (result.result) {
          setInputSucription(initSuscript);
          getSuscriptions();
          //props.history.push("/admin/Students");
        }else{
          alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        }
      });
  }

  function handleChanges(event) {
    const name = event.target.id;
    setInpunts({
      ...inputs,
      [name]: event.target.value
    });
  }

  function handleChangesSusc(event) {
    const name = event.target.id;
    setInputSucription({
      ...inputSuscription,
      [name]: event.target.value
    });
  }

  const [tableStructure, setTableStructure] = useState({
    headers:[{name:"Tipo",type:"text"},{name:"Inicio",type:"text"},{name:"Vencimiento",type:"text"},{name:"Medio de pago",type:"text"},{name:"Monto",type:"text"},{name:"Pais",type:"text"},{name:"Moneda",type:"text"},{name:"Anular",type:"text"}],
    data:[]
  })
  
  
  function getPrice() {
    if(inputSuscription.studentSuscCountry && inputSuscription.studentSuscCountry!="" && inputSuscription.studentSuscPlan!='' && cfg){
      return cfg.prices[inputSuscription.studentSuscCountry][inputSuscription.studentSuscPlan].price || '';
    }
    return '';
  }

  function getCoin(){
    if(inputSuscription.studentSuscCountry && inputSuscription.studentSuscCountry!="" && cfg && cfg.countries.length > 0 ){
      return cfg.countries.find(elem => elem.value==inputSuscription.studentSuscCountry).coin_cod || '';
    }
    return '';
  }

  async function cancelSubscription(id){
    const response = await fetch(`${API_URI}/admin/suscriptions/cancel/`+id);
    const json = await response.json();
    if(json.result){
      getSuscriptions();
    }else{
      alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
    }
  }

  async function getSuscriptions(id){
    var currentId = id ? id : inputs.studentId;
    const response = await fetch(`${API_URI}/admin/suscriptions/`+currentId);
    const json = await response.json();
    const data = [];
    json.forEach((elem,k) => {
      data.push([
        {name:elem.plan.name,type:'text',cfg:{inactive:elem.cancel}},
        {name:elem.start,type:"text"},
        {name:elem.end,type:"text"},
        {name:elem.pay_channel ? elem.pay_channel.name : "", type:"text"},
        {name:elem.price,type:"text"},
        {name:elem.country.name,type:"text"},
        {name:elem.country.coin_cod,type:"text"},
        {name:"Anular",type:'link',to:'',action:()=>{cancelSubscription(elem.id)}},
      ])
    })
    setTableStructure({
      ...tableStructure,
      ['data']:data
    })
  }

  return (
    <div>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={7}
          style={{ marginTop: "-90px", zIndex: "1040" }}
        >
          <Titulo1>Añadir nuevo alumno</Titulo1>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <form>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Nombre del alumno"
                      id="studentName"
                      value={inputs.studentName}
                      error={errors.studentName}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true
                      }}
                      onChange={handleChanges}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Apellido del alumno"
                      id="studentLastname"
                      formControlProps={{
                        fullWidth: true
                      }}
                      value={inputs.studentLastname}
                      error={errors.studentLastname}
                      onChange={handleChanges}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomSelect
                      options={cfg.countries}
                      id="studentCountry"
                      value={inputs.studentCountry}
                      onChange={handleChanges}
                      error={errors.studentCountry}
                      label="País"
                      required={true}
                      name="paisSubsc"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      labelText="E-mail"
                      id="studentEmail"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        type: "email"
                      }}
                      value={inputs.studentEmail}
                      error={errors.studentEmail}
                      onChange={handleChanges}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomSelect
                        options={cfg.status}
                        id="studentState"
                        value={inputs.studentState}
                        error={errors.studentState}
                        required={true}
                        onChange={handleChanges}
                        label="Estado"
                        name="stateId"
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ marginTop: "48px" }}
                  >
                    {!inputs.studentId ? (
                      <Button color="info" onClick={handleSubmit}>
                        Añadir alumno
                      </Button>
                    ) : (
                      <div>
                        <Button color="info" onClick={handleSubmit}>
                          Actualizar alumno
                        </Button>
                      </div>
                    )}
                  </GridItem>
                </GridContainer>
                {inputs.studentId ? (
                  <GridContainer style={{ paddingTop: "24px" }}>
                    <GridItem xs={12} sm={12} md={12}>
                      <span 
                        style={{cursor:'pointer'}}
                        onClick={() => {
                              getSuscriptions()
                              setErrors(errorsInit);
                              setInputSucription(initSuscript);
                              setOpenSusc(!openSusc);
                            }}>
                        <Titulo1>
                          Suscripciones{" "}
                          {openSusc ? (
                            <ExpandLessOutlinedIcon
                              color="info"
                            
                            />
                          ) : (
                            <ExpandMoreOutlinedIcon
                              color="info"
                              
                            />
                          )}
                        </Titulo1>
                      </span>
                      
                    </GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}
                <Collapse in={openSusc}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomSelect
                          options={cfg.countries}
                          id="studentSuscCountry"
                          value={inputSuscription.studentSuscCountry}
                          required={true}
                          error={errors.studentSuscCountry}
                          onChange={handleChangesSusc}
                          label="País"
                          name="paisSubsc"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomSelect
                        options={cfg.plans}
                        label="Suscripción"
                        id="studentSuscPlan"
                        onChange={handleChangesSusc}
                        required={true}
                        error={errors.studentSuscPlan}
                        value={inputSuscription.studentSuscPlan}
                        name="suscriptionType"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomDatePicker
                        formControlProps={{
                          fullWidth: true
                        }}

                        required={true}
                        error={errors.studentSubsStart}
                        value={inputSuscription.studentSubsStart}
                        id="studentSubsStart"
                        onChange={handleChangesSusc}
                        label="Fecha de Inicio"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomDatePicker
                        formControlProps={{
                          fullWidth: true
                        }}
                        value={inputSuscription.studentSubsEnd}
                        required={true}
                        error={errors.studentSubsEnd}
                        id="studentSubsEnd"
                        onChange={handleChangesSusc}
                        label="Fecha de Vencimiento"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                          labelText={"precio "+getCoin()}
                          id="previewPrice"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type:'number'
                          }}
                          disabled
                          value={getPrice()}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomSelect
                          options={cfg.methods}
                          id="studentSuscMethod"
                          value={inputSuscription.studentSuscMethod}
                          onChange={handleChangesSusc}
                          required={true}
                          error={errors.studentSuscMethod}
                          label="Pago"
                          name="medioPago"
                      />
                    </GridItem>


                  </GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ marginTop: "48px" }}
                  >
                    {inputs.studentId ? (
                      <Button color="info" onClick={submithWithSubs}>
                        Actualizar y Renovar Suscripción
                      </Button>
                    ) : (
                      ""
                    )}
                  </GridItem>
                    
                    
                  <GridItem
                  xs={12}
                  sm={12}
                  md={12}>
                    <Table
                      tableHeaderColor="info"
                      tableHead={tableStructure.headers}
                      tableData={tableStructure.data}
                    />
                  </GridItem>
                </Collapse>
              </CardBody>
            </Card>
          </form>
        </GridItem>

        
      </GridContainer>
    </div>
  );
}
