import React,{useState,useEffect} from "react";
import $ from "jquery";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, withStyles, fade} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputBase from '@material-ui/core/InputBase';
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);
const BootstrapInput = withStyles(theme => ({
    root: {
      backgroundColor: '#E0E0E0',
      borderRadius: 4,
      border: '1px solid #ced4da',
      paddingRight:'10px',
      'label + &': {
        marginTop: theme.spacing(3),
      },
      '&:focus': {
        boxShadow: `${fade('#2B84E0', 0.25)} 0 0 0 0.2rem`,
        borderColor: '#2B84E0',
      },

      '&.Mui-error':{
        borderColor:'red',
      }
    },
    label:{
      '&:focus':{
        color:'red'
      },

      'label.Mui-focused': {
        color: 'green !important',
      }
    },
    input: {
      position: 'relative',
      backgroundColor: '#E0E0E0',
      fontSize: 16,
      width: '100%',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&::placeholder':{
        color:'#828282',
        fontStyle:'italic',
        fontFamily:'Rubik'
      }
    },
  }))(InputBase);

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    background,
    endAdornment,
    disabled,
    value,
    onChange,
    key
  } = props;

  
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    //[classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });


  const [valueIntern,setValueIntern] = useState(value)
  useEffect(()=>{
    setValueIntern(value)
  },[value])
  function handlechange(e){
    e.preventDefault()
    const val = e.target.value
    setValueIntern(val)
    onChange(e)
  }

  function isValid(){
    
    return ('validity' in $('#'+id)[0]) && !$('#'+id)[0].validity.valid
  }
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
      error={error && isValid()}
    >

      <InputLabel shrink htmlFor="bootstrap-input">
        {labelText}
      </InputLabel>
      <BootstrapInput classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        id={id}
        disabled={disabled}
        value={valueIntern}
        error={error && isValid()}
        key={key}
        onChange={handlechange}
        endAdornment={endAdornment}
        inputProps={
          {
            maxlength:120
          }
        }
        {...inputProps}/>
    
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};
