import React, { useState, useEffect } from "react";
import $ from "jquery";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import ProgressButton from "components/CustomButtons/ProgressButton.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Titulo1 from "components/Typography/Titulo1.js"
import CustomInputFile from "components/CustomInput/CustomInputFile.js"
import CustomTextArea from "components/CustomInput/CustomTextArea.js"
import CustomSelect from "components/CustomInput/CustomSelect.js"
import SubFormLessons from "components/Courses/SubFormLessons.js"
import SubListLessons from "components/Courses/SubListLessons.js"
import RewardsCourses from "views/Cursos/RewardsCourses.js";
import RecomendedCourses from "views/Cursos/RecomendedCourses.js";
import TableDialog from "components/Dialogs/TableDialog.js"
import TableStudents from "views/Cursos/TableStudents.js";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import { API_URI } from '../../helpers';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function FormCursos(props) {

  const [loading,setLoading] = useState(false);
  const [inputs,setInputs] = useState({
    courseName:"",
    courseVideo:'',
    courseCover:null,
    courseInfo:'',
    courseObjetives:'',
    courseRewards:'',
    courseTiming:'',
    courseLevelId:'',
    courseId:false,
    courseGuitarr:'electrica',
    lessons : [],
    courseRecomended: [],
  })

  const errorsInit = {
    courseName:false,
    courseVideo:false,
    courseCover:false,
    courseInfo:false,
    courseObjetives:false,
    courseRewards:false,
    courseTiming:false,
    courseLevelId:false,
  }

  const [errors,setErrors] = useState(errorsInit)
  const [levels,setLevels] = useState([])
  const [listStudent,setListStudent] = useState({open:false,courseId:false})
  //const [loading,setLoading] = useState(false);

  async function getLevels(){
    const response = await fetch(`${API_URI}/admin/cfg/levels`);
    const json = await response.json();
    setLevels(json);
  }


  useEffect(()=>{
    getLevels()

    if(inputs.toNew){
      setTimeout(function(){
        $('#main-body').animate({
            scrollTop:myRef.current.offsetTop+"px",
        },800)
      },)
      inputs.toNew = false;
    }
    if(props.match.params.courseId){
      fetch(`${API_URI}/admin/course/get/`+parseInt(props.match.params.courseId))
      .then(res => res.json())
      .then(
        (result) => {
          
            const aux = {
              courseName:result.name,
              courseVideo:result.vid_url,
              courseCover:result.cover,
              courseInfo:result.info,
              courseObjetives:result.objetive,
              courseRewards:result.info_rewards,
              courseTiming:result.duration,
              courseLevelId:result.level_id,
              courseId:result.id,
              courseGuitarr:result.guitar,
              courseRecomended: result.recomended
            }
            setInputs(aux);
            setErrors(errorsInit);
            getLessons()
      })
    }
    
  },[inputs.courseId])

  const [level, setLevel] = useState(0);
  function handleSubmit(event) {
    setLoading(true);
    event.preventDefault()
    const data = new FormData();
    if(event.currentTarget.dataset.extra == 'publish'){
      data.append('publish',true);
    }
    var hasErrors = false;
    var aux = {};
    Object.keys(inputs).forEach(function(k){
      
      if(k!='imageUrl'){
        if($('#'+k).length > 0 ){
          if('validity' in $('#'+k)[0] && !$('#'+k)[0].validity.valid){
              hasErrors = true;
              aux[k] = true
            }
        }
        data.append(k,inputs[k]);
      }
    })
    
    if(hasErrors){
      setLoading(false);
      setErrors({
        ...errors,
        ...aux
      })
      $('#'+Object.keys(aux))[0].focus();
      return false
    }
   
    fetch(`${API_URI}/admin/course/save`,{
      method:'POST',
      body:data
    })
    .then((res)=>{
      if(res.status != 200){
        alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        return false
      }
      return res.json()
    })
    .then(
      (result) => {
        console.log(result)
        setLoading(false);
        if(result.result){
          if(inputs.courseId){
            props.history.push("/admin/cursos/Cursos");
          }else{
            setInputs({
              ...inputs,
              ['courseId']: result.insert_id,
              ['toNew'] : true
            });

          }
          
        }else{
          if(result.reason && result.reason=='repeat'){
            alert('Ya existe un curso con este nombre.')
          }
          
        }
      })

  }

  function cancelEdit(){
    props.history.push("/admin/cursos");
  }

  
  function handleChanges(event){
    const name = event.target.id;
    setInputs({
        ...inputs,
        [name]: event.target.value,
      });
  }

  function changeImage(elem){
    inputs['courseCover'] = elem
    setInputs(inputs)
  }


  const [lessonLists,setLessonList] = useState([]);

  async function getLessons(){
    const result = await fetch(`${API_URI}/admin/courses/lessons/`+inputs.courseId);
    const json = await result.json();

    setLessonList(json);
  }
  function handleLessons(less){
    if(!less){
      setLessonID(false)
      return false;
    }
    setLessonID(false)
    getLessons()
  }

  const [lessonID,setLessonID] = useState(false);

  function lessonEdit(id){
    setLessonID(id)
  }

 
  const myRef = React.useRef(null)

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8} style={{marginTop:'-90px', zIndex:'9999'}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <Titulo1>Agregar nuevo Curso.</Titulo1>
            </GridItem>

            <GridItem xs={12} sm={12} md={5} style={{textAlign:'right'}}>
              <Button color="gray" onClick={cancelEdit}>Cancelar</Button>
              <ProgressButton color="info" onClick={handleSubmit} loading={loading} loadingMsg="Guardar">
                  Guardar
              </ProgressButton>
              
            </GridItem>
          </GridContainer>
          
        </GridItem>
          
        <GridItem xs={12} sm={12} md={8}>
          <form>
            
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Nombre del curso"
                    id="courseName"
                    key="courseName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={errors.courseName}
                    value={inputs.courseName}
                    onChange={handleChanges}
                    inputProps={{
                      key:"courseName",
                      required:true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Video introductorio"
                    id="courseVideo"
                    formControlProps={{
                      fullWidth: true,
                      required:true,
                    }}

                    value={inputs.courseVideo}
                    onChange={handleChanges}
                    error={errors.courseVideo}
                    inputProps={{
                      placeholder: 'Cargar URL video',
                      required:true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomTextArea
                    labelText="Sobre este curso"
                    id="courseInfo"
                    value={inputs.courseInfo}
                    onChange={handleChanges}
                    error={errors.courseInfo}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      placeholder:'Agregar descripción',
                      required:true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomTextArea
                    labelText="Lo que aprenderas"
                    id="courseObjetives"
                    error={errors.courseObjetives}
                    value={inputs.courseObjetives}
                    onChange={handleChanges}
                    placeholder=""
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name:"lessons",
                      placeholder:'Agregar descripción',
                      required:true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomTextArea
                    labelText="Los premios que vas a ganar."
                    id="courseRewards"
                    value={inputs.courseRewards}
                    error={errors.courseRewards}
                    onChange={handleChanges}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      placeholder:'Agregar descripción',
                      required:true,
                    }}
                  />
                </GridItem>
               
              </GridContainer>
              
            </CardBody>
          </Card>
        </form>
      {(inputs.courseId)?
        <div ref={myRef}>

          <SubFormLessons 
            onChange={handleLessons}
            id={lessonID}
            course={inputs.courseId}
          />

          <SubListLessons 
            data ={lessonLists}
            onEdit = {lessonEdit}
            idCourse = {inputs.courseId}
            reload = {getLessons}
          />


          <RewardsCourses
            course={inputs.courseId}
          />
          
          <RecomendedCourses
            courseId={inputs.courseId}
            initial={inputs.courseRecomended}
          />
        </div>

      :''}
        </GridItem>

       
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardBody profile>
              <h4>Imagen destacada</h4>
              <CustomInputFile
                value={inputs.courseCover}
                onChange={changeImage}
                typeBtn='link'
                preview={true}
              />              
            </CardBody>
          </Card>
          <Paper style={{marginTop:'40px'}}>
            <Tabs
              value={inputs.courseGuitarr}
              indicatorColor="primary"
              textColor="primary"
              id="courseGuitarr"
              onChange={(eleme,v)=>{
                handleChanges({target:{id:'courseGuitarr',value:v}})
              }}
              aria-label="Tipo de Guitarra"
            >
              <Tab value="acustica" label="Acustica" />
              <Tab value="electrica" label="Electrica" />
            </Tabs>
                
          </Paper>
          <CustomInput 
            labelText="Cantidad de clases."
            id="courseRewards"
            value={lessonLists.length}
            disabled
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              placeholder:'Agregar descripción',
            }}/>

           <CustomInput
              labelText="Duración"
              id="courseTiming"
              value={inputs.courseTiming}
              onChange={handleChanges}
              error={errors.courseTiming}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                placeholder:'dias / hrs',
                required:true,
                type:'number'
              }}
            />

          <CustomSelect
            options={levels}
            label="Nivel"
            name="CourseLevelId"
            id="courseLevelId"
            onChange={handleChanges}
            value={inputs.courseLevelId}
            error={errors.courseLevelId}
            required={true}
          />

          <div style={{marginTop:'48px', textAlign:'center'}} >
            <Button color="info" data-extra="publish" onClick={handleSubmit}>Publicar curso</Button>
          </div>
          {(inputs.courseId)?
          <div style={{marginTop:'96px', textAlign:'center'}} >
            <Button color="info" data-extra="publish" onClick={()=>{setListStudent({open:true,courseId:inputs.courseId})}}>Ver listado de alumnos</Button>
          </div>:""}
          
        </GridItem>
        
      </GridContainer>
      <TableDialog open={listStudent.open} onClose={()=>{setListStudent({open:false,courseId:false})}} width="940px" title='Alumnos en el curso'>
         <div >
            <TableStudents
              tableHeaderColor="info"
              courseID={listStudent.courseId}
            />
         </div>
       </TableDialog>
    </div>
  );
}
