import React, {useState, useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import CustomTextArea from "components/CustomInput/CustomTextArea.js";
import CardBody from "components/Card/CardBody.js";

import { API_URI } from '../../helpers';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};





const useStyles = makeStyles(styles);

export default function Scripts() {
  
  const [scripts, setScripts] = useState({
    globalOriginal:'',
    payOriginal:'',
    pay_successOriginal:'',
    global:'',
    pay:'',
    pay_success:''
  })

  function getOriginals(){
    fetch(`${API_URI}/admin/script/get`)
    .then(res => res.json())
    .then(
      (result) => {
        setScripts({
          globalOriginal:result.global,
          payOriginal:result.pay,
          pay_successOriginal:result.pay_success,
          global:result.global,
          pay:result.pay,
          pay_success:result.pay_success
        })
      }
    )
  }

  useEffect(getOriginals,[]);

 
  function discard(target){
    setScripts({
      ...scripts,
      [target]:scripts[target+'Original']
    })
  }

  function save(target){
    const datos = new FormData();
    datos.append('target',target);  
    datos.append('source',scripts[target]);   
    fetch(`${API_URI}/admin/script/save`,{
      method:'POST',
      body:datos
    })
    .then(res => res.json())
    .then(
      (result) => {
        getOriginals()
      }
    )
  }
  const classes = useStyles();
  return (
    <GridContainer>
      
      <GridItem xs={12} sm={12} md={12}>
        <Card>          
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomTextArea
                  labelText="Pega acá los códigos, se ejecutarán en todas las páginas."
                  id="text"
                  value={scripts.global}
                  onChange={(e)=>{setScripts({...scripts,['global']:e.target.value})}}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    placeholder:'Html el codigo Javascript debe especificarse en etiquetas <script>, puede contener mas de una.',
                    
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{textAlign:'right', marginTop:'30px'}}>
                <Button onClick={()=>{discard('global')}} color="gray"  disabled={scripts.global == scripts.globalOriginal}>Cancelar</Button>
                <Button onClick={()=>{save('global')}} color="info"  disabled={scripts.global == scripts.globalOriginal}>Guardar</Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>          
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomTextArea
                  labelText="Pega acá los códigos, se ejecutarán al mostrar la página de pago."
                  id="text"
                  value={scripts.pay}
                  onChange={(e)=>{setScripts({...scripts,['pay']:e.target.value})}}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    placeholder:'Solo codigo Javascript.',
                    
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{textAlign:'right', marginTop:'30px'}}>
                <Button onClick={()=>{discard('pay')}} color="gray" disabled={scripts.pay == scripts.payOriginal}>Cancelar</Button>
                <Button onClick={()=>{save('pay')}} color="info" disabled={scripts.pay == scripts.payOriginal} >Guardar</Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>          
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomTextArea
                  labelText="Pega acá los códigos, se ejecutarán una vez concretado el pago."
                  id="text"
                  value={scripts.pay_success}
                  onChange={(e)=>{setScripts({...scripts,['pay_success']:e.target.value})}}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    placeholder:'Solo codigo Javascript.',
                    
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{textAlign:'right', marginTop:'30px'}}>
                <Button onClick={()=>{discard('pay_success')}} color="gray" disabled={scripts.pay_success == scripts.pay_successOriginal} >Cancelar</Button>
                <Button onClick={()=>{save('pay_success')}} color="info" disabled={scripts.pay_success == scripts.pay_successOriginal} >Guardar</Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
     
    </GridContainer>
  );
}
