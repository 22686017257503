import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Link from "components/Typography/Link.js";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CustomCheckbox from "components/CustomInput/CustomCheckbox.js"
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

import { API_URI } from '../../helpers';

const useStyles = makeStyles(styles);

export default function TableStudents(props) {
  const classes = useStyles();
  const { courseID, tableHeaderColor, source } = props;
  console.log('holaaa')
  const [data,setData] = useState([])

  const tableHead = ['Nombre','E-mail','Inicio de curso','Porcentaje de curso completado','Clases terminadas'];

  async function listStudents(){
    console.log(source)
      const url = source=='song'?`${API_URI}/admin/song/students/`:`${API_URI}/admin/courses/students/`;
      const resp = await fetch(url+courseID);
      const json = await resp.json();
      setData(json);
   //}
    
  }
  useEffect(()=>{
    console.log('enter');
    listStudents()
  },[])
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    style={{textAlign:'center'}}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {data.map((prop, key) => {
              return (
                <TableRow key={key} className={classes.tableBodyRow}>
                    {
                     Object.keys(prop).map((val,k) => {
                       
                        return (
                          <TableCell  className={classes.tableCell} key={val} style={{textAlign:'center'}}>
                            {prop[val]}      
                          </TableCell>
                        )
                      })
                    }
                   
                  
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </div>
  );
}

TableStudents.defaultProps = {
  tableHeaderColor: "gray"
};
