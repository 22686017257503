import React, {useState, useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Button from "components/CustomButtons/Button.js";

import Searchbar from "components/Navbars/Searchbar";

import {useCookies } from 'react-cookie';

import { API_URI } from '../../helpers';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function ListBanners() {
  const [tableData, setTableData] =  useState({
    headers:[
      {name:"Titulo",type:"text",sortable:'title'},
      {name:"Subtitulo",type:"text",sortable:'subtitle'},
      {name:"Fecha de inicio",type:"text",sortable:'start'},
      {name:"Fecha de Final",type:"text",sortable:'end'},
      {name:"Paises",type:"text"},
      {name:"Estado",type:"text"},
      {name:" ",type:"text"},
      {name:" ",type:"text"}
    ],
    data:[],
    sorting:{},
    pagination:null
  });
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(getListado,[])

  function getListado(params = null){
    const extra = new FormData();
    let srh = (cookies.banners_list && cookies.banners_list.search)?cookies.banners_list.search:null;
    const sort = (cookies.banners_list && cookies.banners_list.sorting)?cookies.banners_list.sorting:null;
    let reset = false;
    //if(params){
      if(params && params.field){
        extra.append('order',params.field);
        extra.append('direc',params.dir)
        reset = true;
      }else if(sort && sort.field){
        extra.append('order',sort.field);
        extra.append('direc',sort.dir)
      }
      
      if(params && params.search && params.search!=""){        
        extra.append('search',params.search);
        reset = true;
      }else if((!params || !('search' in params)) && srh){
        extra.append('search',srh);
      }else{
        if(!params){
          params = {}
        }else if(params.search == ""){
          reset = true;
        }
        params.search = srh = null;
        
      }
      if(params && params.page){
        extra.append('page',params.page);
      }else if(!reset && cookies.banners_list){     
        extra.append('page',cookies.banners_list.paginate.current);
      }
      
    //}
    fetch(`${API_URI}/admin/promotions`,{method:'POST',body:extra})
    .then(res => res.json())
    .then(
      (result) => {
        const data = [];
        result.data.forEach(function(v,k){
          var items = [
            {name:v.title,type:'text'},
            {name:v.subtitle,type:'text'},
            {name:v.start.replace(':00.0000',''),type:'text'},
            {name:(v.end)?v.end.replace(':00.0000',''):'indefinido',type:'text'},
            {name:(v.countries)?v.countries:'Global',type:'text'},
            {name:'',type:'cscheckbox',value:v.id,ini:!v.inactive,legends:['Activo','Inactivo'],callback:toggleBanner},
            {name:'Editar',type:'link',to:'promotion/'+v.id},
            {name:"",type:'delete',callback:deleteBanner,valret:v.id},
          ]
          data.push(items);
          
        })

        const paginate = {'current':result.current_page,'next':result.next_page_url,'prev':result.prev_page_url,'total':result.last_page};
        setTableData({
          ...tableData,
          ['data']:data,
          ['pagination'] : {'current':result.current_page,'next':result.next_page_url,'prev':result.prev_page_url,'total':result.last_page},
          ['sorting']:(params && params.field)?{'field':params.field,'dir':params.dir}:sort,
          ['search'] : (params && params.search)?params.search:srh,
        })

        setCookie('banners_list',{
          'paginate':paginate,
          'sorting':(params && params.field)?{'field':params.field,'dir':params.dir}:sort,
          'search':(params && params.search)?params.search:srh,
        })

      })
  }

  function deleteBanner(id){
    
    const check = window.confirm('Esta seguro de eliminar este banner?')
    if(check){
      const datos = new FormData(); 
      datos.append('id',id);
      fetch(`${API_URI}/admin/promotion/delete`,{
        method:'POST',
        body:datos
      })
      .then(res => res.json())
      .then(
        (result) => {
          getListado()
        }
      )
    }
    
  }
  function toggleBanner(data){
    const datos = new FormData();
    datos.append('id',data.value);
    datos.append('value',data.stat);  
    fetch(`${API_URI}/admin/promotion/toggle`,{
      method:'POST',
      body:datos
    })
    .then(res => res.json())
    .then(
      (result) => {
        
      }
    )
  }
  function ordering(field,dir){
    const obj = {field:field,dir:dir}
    setTableData({
      ...tableData,
      ['data']:[],
      ['sorting']:obj
    })
    getListado(obj);
  }
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{textAlign:'right',marginTop:'-90px', zIndex:'1300'}}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
          <NavLink
                  to={'banner'}
                >
            <Button color="white" >Añadir nuevo</Button>
            </NavLink>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{zIndex:'1040'}}>
            <Searchbar cookie = "banners_list" target={getListado}/>
          </GridItem>
        </GridContainer>
      </GridItem>
      
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <Table
              tableHeaderColor="info"
              tableHead={tableData.headers}
              tableData={tableData.data}
              sortered={tableData.sorting}
              sortCallback={ordering}
              paginate={getListado}
              dtaPage = {tableData.pagination}
            />
          </CardBody>
        </Card>
      </GridItem>
       

    </GridContainer>

     
    

   
  );
}
