// *https://www.registers.service.gov.uk/registers/country/use-the-api*
//import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

export default function AsyncAutocomplete(props) {
  const {url, onChange, label, id, val, filter,asyncProps,error,required, display, value} = props
  const [open, setOpen] = React.useState({open:false,waiting:false});
  const [options, setOptions] = React.useState([]);
  // const [value,setValue] = React.useState('')
  const loading = open.open && open.waiting && options.length === 0;

  const key = (value)?value:'id';
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await fetch(url);
      //await sleep(1e3); // For demo purposes.
      const items = await response.json();

      if (active) {
        setOptions(items);
        setOpen({open:open.open,waiting:false})
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open.open) {
      setOptions([]);
    }
  }, [open.open]);

  return (
    <Autocomplete
      id={id}
      style={{ width: '100%' }}
      open={open.open}
      value={val}
      onOpen={() => {
        setOpen({open:true,waiting:true});
      }}
      onClose={() => {
        setOpen({open:false,waiting:false});
      }}
      filterOptions={filter}
      onChange={onChange}
      getOptionSelected={(option, value) => option[key] === value[key]}
      getOptionLabel={option => (display)?option[display]:option.name}
      options={options}
      loading={loading}
      {...asyncProps}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={error&&!val.id}
          InputProps={{
            ...params.InputProps,
            required:required,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
