import React, {useState, useEffect} from "react";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.js"
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import {useCookies } from 'react-cookie';
const useStyles = makeStyles(styles);

export default function Searchbar(props){
    const {style,target, cookie} = props;
    const classes = useStyles();

    const [cookies, setCookie, removeCookie] = useCookies();
    const [value,setValue] = useState((cookie in cookies)?cookies[cookie].search:'');
    function doSearch(){
      target({search:value});
    }
    return (
        <CustomInput
          style={{width:'100px'}}
          background='white'
          formControlProps={{
            className: classes.margin + " " + classes.search
          }}
          inputProps={{
            placeholder: "Buscar",
            inputProps: {
              "aria-label": "Search"
            }
          }}
          value={value}
          onChange={(e)=>{setValue(e.target.value)}}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={doSearch}
              >
                <Search />
              </IconButton>
            </InputAdornment>,
            <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={doSearch}
            >
              <Search />
            </IconButton>
          </InputAdornment>
          }
        />
    )
}
