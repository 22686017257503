import React, {useState, useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {useCookies } from 'react-cookie';

import Link from "components/Typography/Link.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks(props) {
  const classes = useStyles();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };


  const [cookies, setCookie, removeCookie] = useCookies();

  function signOut(){
    ['api','course_list','students_list','mail_list'].forEach((v,k)=>{
      
      if(v in cookies){
        removeCookie(v);
      }
      
    })
    //props.history.push("/login");
  }
  return (
    <div>
      <div className={classes.searchWrapper}>
        <Link to="" onClick={signOut}  style={{marginLeft:'20px',fontSize:'.8em',display:'inline'}}>Cerrar Sesión</Link>
      </div>
      
      
    </div>
  );
}
