import React,{useState, useRef, useEffect} from "react";
import $ from "jquery";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, withStyles, fade} from "@material-ui/core/styles";
import Link from "components/Typography/Link.js"
import ClearIcon from '@material-ui/icons/Clear';
// core components
import imgDefault from "assets/img/default.jpg";
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

export default function CustomInputFiles(props) {
  const classes = useStyles();
  const [imageUrl,setImageUrl] = useState({url:'',name:''})
  const {
    typeBtn,
    label,
    id,
    onChange,
    preview,
    accept,
    value
  } = props;


  useEffect(()=>{
    //console.log('change',typeof(value))
    if(typeof(value) == 'string' || typeof(value) == null){
      console.log('entro')
      setImageUrl({url:value,name:value})
    }
    
  },[value])

  const inputRef = useRef(null)
  function handleChange(e){
    if(!e){
      setImageUrl({url:'',name:''})
      onChange('')
      return false
    }
    let reader = new FileReader()
    let file = e.target.files[0];
    if(!file){
      return false;
    }
    reader.onloadend = () => {
      
      setImageUrl({url:reader.result,name:file.name})
      onChange(file)
    }
    reader.readAsDataURL(file)
  }

  function handleClick(e){
    e.preventDefault()
    inputRef.current.click()
  }

  function clear(e){
    e.preventDefault()
    //console.log(inputRef.current.value)
    $(inputRef.current).val('');
    handleChange(false);
  }

  return (
      <div>
        <input
          accept={accept}
          id={id || 'customFile'}
          style={{display:'none'}}
          onChange={handleChange}
          ref={inputRef}
          type="file"
        />
        <label htmlFor={id || 'customFile'}>
          {typeBtn == "link"?
          <>
            <Link to={false} for={id || 'customFile'} onClick={handleClick}>{label?label:"Cargar imagen destacada"}</Link>
            {(value)?<span onClick={clear} style={{verticalAlign:'middle'}}><ClearIcon/></span>:''}
          </>
          :
          <span>
          <Button for={id || 'customFile'} color="white" onClick={handleClick}>
            {label?label:"Cargar imagen destacada"}
          </Button>
          {(value)?<span onClick={clear} style={{verticalAlign:'middle'}}><ClearIcon/></span>:''}
          </span>
          
        }
        </label>
        <br/>
        <Link to={imageUrl.url} ext={true} color={'#828282'} style={{
          fontSize:'13px', 
          color:'#828282',
          fontStyle:'italic',
          fontFamily:'Rubik'
        }}>{imageUrl.name}</Link>
        <div>
          {preview?(imageUrl.url=='')?<img src={imgDefault}  height="200px" style={{maxWidth:'100%'}} />:<img src={imageUrl.url} style={{maxWidth:'100%'}} height="200px"/>:''}
        </div>
      </div>
      
      
  );
}

CustomInputFiles.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};
