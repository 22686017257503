import React,{useState,useEffect} from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, withStyles, fade} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputBase from '@material-ui/core/InputBase';
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTextArea(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    background,
    endAdornment,
    disabled,
    value,
    onChange,
  } = props;

  
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });


  const [valueIntern,setValueIntern] = useState(value)

  return (
    <FormControl
      {...formControlProps}
      error={error && value==''}
      className={formControlProps.className + " " + classes.formControl}
    >

            <label htmlFor="exampleFormControlTextarea1" style={(error && value=="")?{color:'red'}:{}}>
            {labelText}
            </label>
            
            <TextareaAutosize

            error={error && value==""}
            style={{
              border: '1px solid',
              borderColor:(error && value=="")?'red':'#ced4da',
              borderRadius: '4px',
              paddingRight: '10px',
              backgroundColor: '#E0E0E0',
              padding: '12px',
              fontSize:'16px',
              fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
              ].join(','),
            }}
            value={value}
            onChange={onChange}
            className="form-control"
            id={id}
            rows="5"
            disabled={disabled}
            {...inputProps}
            />

      
    </FormControl>
  );
}

CustomTextArea.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};
