import React, { useState, useEffect, Fragment } from "react";
import $ from "jquery";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Grid from "@material-ui/core/Grid";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDateTimePicker from "components/CustomInput/CustomDateTimePicker.js";
import FormCheckbox from "components/CustomInput/FormCheckbox.js"
import AsyncAutocomplete from 'components/CustomInput/AsyncAutocomplete.js'
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomColorPicker from "components/CustomInput/CustomColorPicker";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Titulo1 from "components/Typography/Titulo1.js";

import { API_URI } from '../../helpers';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function FormBanners(props) {

  const [cfg, setCfg] = useState([]);

  function handleSubmit(event) {
    console.log(inputs);
    event.preventDefault();
    const data = new FormData();
    const aux = {};
    Object.keys(inputs).forEach(function(k) {
      if (k && $("#" + k).length > 0) {
        if ("validity" in $("#" + k)[0] && !$("#" + k)[0].validity.valid) {
          aux[k] = true;
        }
      }
      if(k=='countries'){
        inputs.countries.forEach(element => {
          data.append('countries[]',parseInt(element.value))
        });
      }else{
        data.append(k, inputs[k]);
      }
      
    });
    
    if (Object.keys(aux).length > 0) {
      setErrors({
        ...errors,
        ...aux
      });
      $("#" + Object.keys(aux))[0].focus();
      return false;
    }

    fetch(`${API_URI}/admin/promotion/save`, {
      method: "POST",
      body: data
    })
      .then((res)=>{
        if(res.status != 200){
          alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
          return false
        }
        return res.json()
      })
      .then(result => {
        if (result.result) {
          props.history.push("/admin/promotions");
        }else{
          alert( "No se ha podido procesar tu solicitud. Reintenta más tarde por favor!");
        }
      });
  }


  const [inputs, setInpunts] = useState({
    title: "",
    subtitle: "",
    color: "#FF0000",
    background: "#FFFFFF",
    start:null,
    end:null,
    inactive:false,
    show_timer:true,
    bannerId: false,
    target:'',
    countries:[],
    renovationDays: 0,
    extraText: '',
    detailsText: '',
    onlyWithLink: false,
    automaticRenewal: false,
    promotionTimeEnd: 0,
    link: '',
    suscriptionDuration: '',
    bannerButtonEnabled: false,
    bannerButtonText: '',
    bannerButtonTextColor: "#FFFFFF",
    bannerButtonBackgroundColor: "#2B84E0",
    fixed: false
  });


  const errorsInit = {
    title: false,
    subtitle: false,
    color: false,
    background: false,
    start: false,
    end:false,
    inactive: false,
    show_timer: false,
    bannerId: false,
    target: false,
    countries: false,
    extraText: false,
    detailsText: false,
    renovationDays: false,
    promotionTimeEnd: false,
    link: false,
    suscriptionDuration: false,
    bannerButtonEnabled: false,
    bannerButtonText: false,
    bannerButtonTextColor: false,
    bannerButtonBackgroundColor: false,
    fixed: false
  };

  const [errors, setErrors] = useState(errorsInit);

  useEffect(() => {
    if (props.match.params.bannerId) {
      fetch(
        `${API_URI}/admin/promotion/` +
          parseInt(props.match.params.bannerId)
      )
        .then(res => res.json())
        .then(result => {
          const data = {
            title: result.title,
            subtitle: result.subtitle,
            color: result.color,
            background: result.background,
            start:result.start,
            end:result.end,
            inactive:!result.inactive,
            show_timer:Boolean(result.show_time),
            bannerId: result.id,
            target:result.target,
            countries:result.countries,
            renovationDays: result.renovationDays,
            extraText: result.extraText,
            detailsText: result.detailsText,
            onlyWithLink: Boolean(result.onlyWithLink),
            automaticRenewal: Boolean(result.automaticRenewal),
            promotionTimeEnd: result.promotionTimeEnd,
            link: result.link,
            suscriptionDuration: result.suscriptionDuration,
            bannerButtonEnabled: Boolean(result.bannerButtonEnabled),
            bannerButtonText: result.bannerButtonText,
            bannerButtonTextColor: result.bannerButtonTextColor,
            bannerButtonBackgroundColor: result.bannerButtonBackgroundColor,
            fixed: result.fixed
          };

          setInpunts(data);
        });
    }
    getCfg();
  }, []);

  async function getCfg() {
    const resp = await fetch(
      `${API_URI}/admin/cfg/targets`
    );
    const json = await resp.json();
      console.log(json)
    setCfg(json);
  }

  function handleChanges(event) {
    const name = event.target.id;
    setInpunts({
      ...inputs,
      [name]: (event.target.type == "checkbox")?(event.target.value=='true'):event.target.value
    });
  }

  return (
    <div>
      <GridContainer>

        <GridItem xs={12} sm={12} md={12} style={{marginTop:'-90px', zIndex:'1040'}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <Titulo1>{(inputs.id)?'Agregar nuevo banner.':'Editar banner.'}</Titulo1>
            </GridItem>

            <GridItem xs={12} sm={12} md={5} style={{textAlign:'right'}}>
              <Button color="gray" onClick={()=>{props.history.push("/admin/promotions");}}>Cancelar</Button>
              <Button color="info" onClick={handleSubmit}>
                  Guardar
              </Button>
              
            </GridItem>
          </GridContainer>
          
        </GridItem>

        <GridItem xs={12} sm={12} md={10}>
          <form>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={11}>
                    <CustomInput
                      labelText="Titulo"
                      id="title"
                      value={inputs.title}
                      error={errors.title}
                      formControlProps={{ 
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true
                      }}
                      onChange={handleChanges}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={11}>
                    <CustomInput
                      labelText="Subtitulo"
                      id="subtitle"
                      formControlProps={{
                        fullWidth: true
                      }}
                      value={inputs.subtitle}
                      error={errors.subtitle}
                      inputProps={{
                        required: true
                      }}
                      onChange={handleChanges}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={11}>
                      <CustomInput
                        labelText="Texto Adicional"
                        id="extraText"
                        formControlProps={{
                          fullWidth: true
                        }}
                        value={inputs.extraText}
                        error={errors.extraText}
                        onChange={handleChanges}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={11}>
                      <CustomInput
                        labelText="Texto Detalle"
                        id="detailsText"
                        formControlProps={{
                          fullWidth: true
                        }}
                        value={inputs.detailsText}
                        error={errors.detailsText}
                        onChange={handleChanges}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Duración Suscripción (Días)"
                        id="suscriptionDuration"
                        formControlProps={{
                          fullWidth: true
                        }}
                        value={inputs.suscriptionDuration}
                        error={errors.suscriptionDuration}
                        onChange={handleChanges}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Hora Fin Promoción"
                        id="promotionTimeEnd"
                        formControlProps={{
                          fullWidth: true
                        }}
                        value={inputs.promotionTimeEnd}
                        error={errors.promotionTimeEnd}
                        inputProps={{
                          required: true
                        }}
                        onChange={handleChanges}
                      />
                    </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomDateTimePicker
                        formControlProps={{
                          fullWidth: true
                        }}

                        required={true}
                        error={errors.start}
                        value={inputs.start}
                        id="start"
                        onChange={handleChanges}
                        label="Fecha de Inicio"
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomDateTimePicker
                        formControlProps={{
                          fullWidth: true
                        }}

                        error={errors.end}
                        value={inputs.end}
                        id="end"
                        onChange={handleChanges }
                        label="Fecha de Final (opcional)"
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={11}>
                      <FormCheckbox
                          id='show_timer'
                          label = 'Mostrar contador'
                          onChange={handleChanges}
                          value={inputs.show_timer}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomColorPicker
                      name='color'
                      id="color"
                      value={inputs.color}
                      error={errors.color}
                      labelText="Color"
                      onChange={handleChanges}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomColorPicker
                      name='background'
                      id="background"
                      value={inputs.background}
                      labelText="Background"
                      onChange={handleChanges}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={11}>
                      <AsyncAutocomplete 
                        url={`${API_URI}/admin/cfg/countries`}
                        id="countries"
                        label="Seleccione los paises donde va a verse."
                        display="display"
                        value="value"
                        asyncProps={{
                          multiple:'multiple'
                        }}
                        val={inputs.countries}
                        onChange={(e,v)=>{
                          setInpunts({
                            ...inputs,
                            ['countries']:v
                          })
                        }}
                      />
                  </GridItem>

                   <GridItem xs={12} sm={12} md={4}>
                   <CustomSelect
                        options={cfg}
                        id="target"
                        value={inputs.target}
                        required={true}
                        error={errors.target}
                        onChange={handleChanges}
                        label="Listado de alumnos"
                        name="paisSubsc"  
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={11}>
                      <FormCheckbox
                          id='inactive'
                          legends = {['Activo','Inactivo']}
                          onChange={handleChanges}
                          value={inputs.inactive} 
                      />
                  </GridItem>
                
                  <GridItem xs={12} sm={12} md={11}>
                      <FormCheckbox
                          id='fixed'
                          legends = {['Mostrar banner siempre arriba','Mostrar banner siempre arriba']}
                          onChange={handleChanges}
                          value={inputs.fixed} 
                      />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={11}>
                      <FormCheckbox
                          id='automaticRenewal'
                          label = 'Renovación Diaria Automatica'
                          onChange={handleChanges}
                          value={inputs.automaticRenewal}
                      />
                  </GridItem>
                
                  <GridItem xs={12} sm={12} md={11}>
                      <FormCheckbox
                          id='onlyWithLink'
                          label = 'Solo con Link'
                          onChange={handleChanges}
                          value={inputs.onlyWithLink}
                      />
                      <div style={{marginTop: '12px'}}>
                        URL <span style={{fontWeight: 'bold'}}>?promo={inputs.link}</span>
                      </div>
                  </GridItem>

                  {inputs.onlyWithLink ? 
                  <div style={{width: '100%', display: 'flex', flexWrap: 'wrap'}}>
                    <GridItem xs={12} sm={6} md={5}>
                      <CustomInput
                        labelText="Cantidad Dias de Renovación"
                        id="renovationDays"
                        formControlProps={{
                          fullWidth: true
                        }}
                        value={inputs.renovationDays}
                        error={errors.renovationDays}
                        inputProps={{
                          required: true
                        }}
                        onChange={handleChanges}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={5}>
                      <CustomInput
                        labelText="Link"
                        id="link"
                        formControlProps={{
                          fullWidth: true
                        }}
                        value={inputs.link}
                        error={errors.link}
                        inputProps={{
                          required: true
                        }}
                        onChange={handleChanges}
                      />
                    </GridItem>
                    </div>
                  : null }

                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                        labelText="Texto Botón"
                        id="bannerButtonText"
                        formControlProps={{
                          fullWidth: true
                        }}
                        value={inputs.bannerButtonText}
                        error={errors.bannerButtonText}
                        onChange={handleChanges}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={11}>
                      <FormCheckbox
                          id='bannerButtonEnabled'
                          label = 'Mostrar Botón'
                          onChange={handleChanges}
                          value={inputs.bannerButtonEnabled}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomColorPicker
                      name='bannerButtonTextColor'
                      id="bannerButtonTextColor"
                      value={inputs.bannerButtonTextColor}
                      error={errors.bannerButtonTextColor}
                      labelText="bannerButtonTextColor"
                      onChange={handleChanges}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomColorPicker
                      name='bannerButtonBackgroundColor'
                      id="bannerButtonBackgroundColor"
                      value={inputs.bannerButtonBackgroundColor}
                      labelText="bannerButtonBackgroundColor"
                      onChange={handleChanges}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>

                </GridContainer>
                
               </CardBody>
            </Card>
          </form>
        </GridItem>

        
      </GridContainer>
    </div>
  );
}
