import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Link from "components/Typography/Link.js";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CustomCheckbox from "components/CustomInput/CustomCheckbox.js"
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function TableSubscriptions(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  let prev = '';
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    style={(prop.color)?{color:prop.color}:{}}
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            
            return prop.subs.map((val,k)=>{
              return (
                <TableRow key={key} className={classes.tableBodyRow}>

                  {(k==0)?

                    <TableCell rowSpan={prop.subs.length} className={classes.tableCell} key={key}>
                      {prop.name}      
                    </TableCell>
                    : ''
                  }
                  {(k==0)?
                   <TableCell rowSpan={prop.subs.length} className={classes.tableCell} key={key}>
                   {prop.subs.length} Suscripciones
                 </TableCell>:''}
                  <TableCell className={classes.tableCell} key={k}>
                        {k+1} - suscripción ({val.plan.name})
                  </TableCell>
                  <TableCell className={classes.tableCell} key={k}>
                        {val.pay_channel.name}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={k}>
                        {val.start}
                  </TableCell>
                  
                  <TableCell className={classes.tableCell} key={k}>
                        {val.end}
                  </TableCell>
                  
                </TableRow>
              );
            })
          })}
        </TableBody>
      </Table>
    </div>
  );
}

TableSubscriptions.defaultProps = {
  tableHeaderColor: "gray"
};
