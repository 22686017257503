import React, {useState, useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

import Button from "components/CustomButtons/Button.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Link from "components/Typography/Link.js";
import Searchbar from "components/Navbars/Searchbar";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {useCookies } from 'react-cookie';

import { API_URI } from '../../helpers';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};





const useStyles = makeStyles(styles);

export default function ListCursos(props) {

  const [anchor,setAnchor] = React.useState(null);

  const handleMenuItemClick = (event, index) => {
    
    
  };

  const [cookies, setCookie, removeCookie] = useCookies();

  const handleClose = () => {
    setAnchor(null);
  };
  const [tableData, setTableData] =  useState({
    header:[
      {name:"",type:"checkbox"},
      {name:"Nombre",type:"text",sortable:'name'},
      {name:"Tipo",type:"text",sortable:'source'},
      {name:"Categoría",type:"text"},
      {name:"fecha",type:"text",sortable:'created_at'},
      {name:"Estado",type:"text",sortable:'inactive'},
      {name:"",type:"text"}
    ],
    data:[],
    sorting:{},
    pagination:null,
    search:null
  });
  const [toDelete,setToDelete] = useState([]);
  const [resetTable,setResetTable] = useState(false);

  function reset(){
    setResetTable(!resetTable);
  }

  function getList(params = null){
    const extra = new FormData();
   let srh = (cookies.course_list && cookies.course_list.search)?cookies.course_list.search:null;
   const sort = (cookies.course_list && cookies.course_list.sorting)?cookies.course_list.sorting:null;
   let reset = false;
    //if(params){
      if(params && params.field){
        extra.append('order',params.field);
        extra.append('direc',params.dir)
        reset = true;
      }else if(sort && sort.field){
        extra.append('order',sort.field);
        extra.append('direc',sort.dir)
      }
      
      if(params && params.search && params.search!=""){        
        extra.append('search',params.search);
        reset = true;
      }else if((!params || !('search' in params)) && srh){
        extra.append('search',srh);
      }else{
        if(!params){
          params = {}
        }else if(params.search == ""){
          reset = true;
        }
        params.search = srh = null;
        
      }
      if(params && params.page){
        extra.append('page',params.page);
      }else if(!reset && cookies.course_list){     
        extra.append('page',cookies.course_list.paginate.current);
      }

    var serviceUri = props.match.params.type == "Canciones" ? `${API_URI}/admin/songs` : `${API_URI}/admin/courses`;
    
    fetch(serviceUri,{method:'POST',body:extra})
    .then(res => res.json())
    .then(
      (result) => {
        const data = [];
        result.data.forEach(function(v,k){
          var items = [
            {name:'',type:'cscheckbox',value:v.id,source:v.source,ini:false,resetable:true,legends:['',''],callback:actionCheck},
            {name:v.name,type:'text'},
            {name:v.source,type:'text'},
            {name:'Generica',type:'text'},
            {name:v.created,type:'text'},
            {name:'',type:'cscheckbox',value:v.id,source:v.source,ini:!v.inactive,legends:['Activo','Inactivo'],callback:toggleCurso},
            {name:'editar',type:'link',to:((v.source=='Curso')?'/admin/curso-nuevo/':'/admin/song/')+v.id},
            {name:"",type:'delete',source:v.source,callback:deleteCourse,valret:{id:v.id,source:v.source}},
          ]
          data.push(items);
        })
        const paginate = {'current':result.current_page,'next':result.next_page_url,'prev':result.prev_page_url,'total':result.last_page};
        setTableData({
          ...tableData,
          ['data']:data,
          ['pagination'] : {'current':result.current_page,'next':result.next_page_url,'prev':result.prev_page_url,'total':result.last_page},
          ['sorting']:(params && params.field)?{'field':params.field,'dir':params.dir}:sort,
          ['search'] : (params && params.search)?params.search:srh,
        })
        setCookie('course_list',{
          'paginate':paginate,
          'sorting':(params && params.field)?{'field':params.field,'dir':params.dir}:sort,
          'search':(params && params.search)?params.search:srh,
        })
      })
  }

  useEffect(()=>{
    if (cookies && cookies.course_list && cookies.course_list.paginate) {
      cookies.course_list.paginate.current = 1;
    }

    getList();
  },[props.match.params.type])

  function ordering(field,dir){
    const obj = {field:field,dir:dir}
    setTableData({
      ...tableData,
      ['data']:[],
      ['sorting']:{field:field,dir:dir}
    })
    getList(obj);
  }

  function massiveDelete(){
    if(toDelete.length==0){
      alert('no ha seleccionado ningun curso')
      
    }else{
      
      deleteCourse(toDelete);
    }

    
  }

  function deleteCourse(id){
    let sended = "";
    let msg = "";
    
    if(Array.isArray(id)){
      msg = "Esta seguro que desea eliminar estos cursos?";
      sended = JSON.stringify(id)
    }else{
      msg = "Esta seguro que desea eliminar este Curso?";
      sended = JSON.stringify([id])
    }
    const check = window.confirm(msg)
    if(check){
      const datos = new FormData();
      datos.append('ids',sended);   
      fetch(`${API_URI}/admin/course/delete`,{
        method:'POST',
        body:datos
      })
      .then(res => res.json())
      .then(
        (result) => {
          getList()
          setToDelete([]);
          reset();
        }
      )
    }
    
  }
  function toggleCurso(data){
    const datos = new FormData();
    datos.append('id',data.value.id);
    datos.append('value',data.stat);
    datos.append('source',data.value.source);   
    fetch(`${API_URI}/admin/courses/toggle`,{
      method:'POST',
      body:datos
    })
    .then(res => res.json())
    .then(
      (result) => {
        
      }
    )
  }
  const listDelete = [];
  function actionCheck(data){
    if(data.stat){
      listDelete.push(data.value)
    }else{
      const indx = listDelete.indexOf(data.value)
      listDelete.splice(indx,1);
    }

    setToDelete(listDelete);
  }
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}  style={{textAlign:'right',marginTop:'-90px'}}>
        <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Button style={{ zIndex:'2040'}} onClick={(evnt)=>{setAnchor(evnt.currentTarget)}} color="white">Añadir nuevo</Button>

          <StyledMenu
            id="lock-menu"
            anchorEl={anchor}
            keepMounted
            
            open={Boolean(anchor)}
            onClose={handleClose}
          >
            {['Curso','Canción'].map((option, index) => (
              <MenuItem
                key={option}
                onClick={event => handleMenuItemClick(event, index)}
                style={{width:'175px'}}
              >
                {(index==0)?
                  <NavLink
                    style={{color:'#999',width:'100%'}}
                    to={'/admin/curso-nuevo'}
                  >
                    {option} 
                  </NavLink>
                :
                <NavLink
                    style={{color:'#999',width:'100%'}}
                    to={'/admin/song'}
                  >
                    {option} 
                  </NavLink>
              }
                
                
              </MenuItem>
            ))}
          </StyledMenu>
          
        </GridItem>
        <GridItem xs={12} sm={12} md={4} style={{zIndex:'2040'}}>
          <Searchbar cookie = "course_list" target={getList}/>
        </GridItem>
        </GridContainer>
      </GridItem>
      
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Link to="" onClick={massiveDelete}>Eliminar cursos</Link>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="info"
              tableHead={tableData.header}
              tableData={tableData.data}
              sortered={tableData.sorting}
              sortCallback={ordering}
              paginate={getList}
              dtaPage = {tableData.pagination}
              reset = {resetTable}
            />
          </CardBody>
        </Card>
      </GridItem>
     
    </GridContainer>
  );
}
