import React,{useState} from "react";
import Checkbox from '@material-ui/core/Checkbox';


export default function FormCheckbox(props) {
    const {color, onChange, disabled, value, label, legends, id,inverted} = props;

    
    function fnChange(e){
        e.target.value = e.target.checked;
        
        onChange(e)
        
    }
    
    return (
        <div>
            <Checkbox
                id={id || ''}
                color={color || 'info'}
                onChange={fnChange}
                value={value}
                checked={(inverted)?!value:value}
                disabled={disabled}
            />
            {(Array.isArray(legends))?(value)?legends[0]:legends[1] : label}
        </div>
       
    )
}