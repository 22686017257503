import React, {useState, useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TableExpandable from "components/Table/TableExpandable.js";
import TableSubscriptions from "views/Students/TableSubscriptions.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Titulo1 from "components/Typography/Titulo1";
import Button from "components/CustomButtons/Button.js";
import TableDialog from "components/Dialogs/TableDialog.js"
import Searchbar from "components/Navbars/Searchbar";
import Link from "components/Typography/Link.js";
import {useCookies } from 'react-cookie';

import { API_URI } from '../../helpers';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

var tableStructure = {
  headers:[{name:"Nombre",type:"text"},{name:"E-mail",type:"text"},{name:"Registro",type:"text"},{name:"Suscripción",type:"text"},{name:"Fecha de inicio",type:"text"},{name:"Fecha de vto.",type:"text"},{name:"Estado",type:"text"},{name:" ",type:"text"}],
  
}


const useStyles = makeStyles(styles);

export default function ListStudents() {
  const [tableData, setTableData] =  useState({
    headers:[{name:"Nombre",type:"text",sortable:'name'},{name:"Apellido",type:"text",sortable:'lastname'},{name:"E-mail",type:"text",sortable:'mail'},{name:"Registro",type:"text",sortable:'created'},{name:"Suscripción",type:"text"},{name:"Fecha de inicio",type:"text",sortable:'start'},{name:"Fecha de vto.",type:"text",sortable:'end'},{name:"Estado",type:"text"},{name:" ",type:"text"}],
    data:[],
    sorting:{},
    pagination:null
  });
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(getListado,[]
  )

  function getListado(params = null){
    const extra = new FormData();
    // let srh = (tableData.search)?JSON.parse(JSON.stringify(tableData.search)):null;
    // const sort = (tableData.sorting)?JSON.parse(JSON.stringify(tableData.sorting)):null;
    console.log('cookie',cookies)
    let srh = (cookies.students_list && cookies.students_list.search)?cookies.students_list.search:null;
    const sort = (cookies.students_list && cookies.students_list.sorting)?cookies.students_list.sorting:null;
    let reset = false;
    //if(params){
      if(params && params.field){
        extra.append('order',params.field);
        extra.append('direc',params.dir)
        reset = true;
      }else if(sort && sort.field){
        extra.append('order',sort.field);
        extra.append('direc',sort.dir)
      }
      
      if(params && params.search && params.search!=""){        
        extra.append('search',params.search);
        reset = true;
      }else if((!params || !('search' in params)) && srh){
        extra.append('search',srh);
      }else{
        if(!params){
          params = {}
        }else if(params.search == ""){
          reset = true;
        }
        params.search = srh = null;
        
      }
      if(params && params.page){
        extra.append('page',params.page);
      }else if(!reset && cookies.students_list){     
        extra.append('page',cookies.students_list.paginate.current);
      }
      
    //}
    fetch(`${API_URI}/admin/students`,{method:'POST',body:extra})
    .then(res => res.json())
    .then(
      (result) => {
        const data = [];
        result.data.forEach(function(v,k){
          var items = {
            items:[
              {name:v.items.name,type:'text'},
              {name:v.items.lastname,type:'text'},
              {name:v.items.mail,type:'text'},
              {name:v.items.created,type:'text'},
              {name:(v.items.subscription)?(v.items.subscription.plan !== null)?v.items.subscription.plan.name:''+' - '+(v.items.subscription.pay_channel !==null)?v.items.subscription.pay_channel.name:'':'-',type:'text'},
              {name:(v.items.subscription)?v.items.subscription.start:'',type:'text'},
              {name:(v.items.subscription)?v.items.subscription.end:'',type:'text'},
              {name:(v.items.state),type:'text'},
              {name:'Editar',type:'link',to:'Student-new/'+v.items.id},
            ],
            expand:v.extras
          }
          data.push(items);
        })

        const paginate = {'current':result.current_page,'next':result.next_page_url,'prev':result.prev_page_url,'total':result.last_page};
        setTableData({
          ...tableData,
          ['data']:data,
          ['pagination'] : {'current':result.current_page,'next':result.next_page_url,'prev':result.prev_page_url,'total':result.last_page},
          ['sorting']:(params && params.field)?{'field':params.field,'dir':params.dir}:sort,
          ['search'] : (params && params.search)?params.search:srh,
        })

        setCookie('students_list',{
          'paginate':paginate,
          'sorting':(params && params.field)?{'field':params.field,'dir':params.dir}:sort,
          'search':(params && params.search)?params.search:srh,
        })

      })
  }
  
  function ordering(field,dir){
    const obj = {field:field,dir:dir}
    setTableData({
      ...tableData,
      ['data']:[],
      ['sorting']:obj
    })
    getListado(obj);
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{textAlign:'right',marginTop:'-90px', zIndex:'1300'}}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
          <NavLink
                  to={'Student-new'}
                >
            <Button color="white" >Añadir nuevo</Button>
            </NavLink>
          </GridItem>
          <GridItem xs={12} sm={12} md={3} style={{zIndex:'2040'}}>
            <Searchbar cookie = "students_list" target={getListado}/>
          </GridItem>
        </GridContainer>
      </GridItem>
      
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <TableExpandable
              tableHeaderColor="info"
              tableHead={tableData.headers}
              tableData={tableData.data}
              sortered={tableData.sorting}
              sortCallback={ordering}
              paginate={getListado}
              dtaPage = {tableData.pagination}
              config={['120px','120px','120px','100px','50px','80px','80px','80px','40px']}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>

     
    

   
  );
}
