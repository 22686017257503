import React, {useState, useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomDatePicker from "components/CustomInput/CustomDatePicker.js";
import Button from "components/CustomButtons/Button.js";

import Searchbar from "components/Navbars/Searchbar";

import {useCookies } from 'react-cookie';

import { API_URI, formatDate } from '../../helpers';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Rubik','Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

export default function ListStudents() {
  const [tableData, setTableData] =  useState({
    headers:[
      {name:"Fecha",type:"text",sortable:'created_at'},
      {name:"Pais",type:"text",sortable:'country'},
      {name:"Nombre",type:"text",sortable:'student.name'},
      {name:"Apellido",type:"text",sortable:'student.lastname'},
      {name:"Mail",type:"text",sortable:'student.mail'},
      {name:"Precio Local",type:"text",sortable:'price',align:"right"},
      {name:"Precio USD",type:"text",sortable:'price_usd',align:"right"},
      {name:"Renovación",type:"text",sortable:'price_usd',align:"right"}
    ],
    data:[],
    sorting:{},
    pagination:null
  });
  const [countriesTableData, setCountriesTableData] =  useState({
    headers:[
      {name:"Pais",type:"text",sortable:'created_at'},
      {name:"Total USD",type:"text",sortable:'country'},
      {name:"Cantidad Ventas",type:"text",sortable:'student.name'}
    ],
    data:[]
  });
  const [cookies, setCookie, removeCookie] = useCookies();

  function getDefaultStartDate() {
    var date = new Date(Date.now());

    if (date.getHours() <= 3) {
      date.setDate(date.getDate() - 1);
    }

    return date;
  }

  const [inputs, setInpunts] = useState({
    startDate: formatDate(getDefaultStartDate()),
    endDate: null,
  });

  const [globalValues, setGlobalValues] = useState({
    totalPrice: null,
    totalSales: null
  });

  function handleChanges(event) {
    console.log(event)
    const name = event.target.id;
    setInpunts({
      ...inputs,
      [name]: event.target.value
    });
  }

  useEffect(getListado,[])

  function getListado(params = null){
    const extra = new FormData();
    let srh = (cookies.suscriptions_list && cookies.suscriptions_list.search)?cookies.suscriptions_list.search:null;
    const sort = (cookies.suscriptions_list && cookies.suscriptions_list.sorting)?cookies.suscriptions_list.sorting:null;
    let reset = false;
      if(params && params.field){
        extra.append('order',params.field);
        extra.append('direc',params.dir)
        reset = true;
      }else if(sort && sort.field){
        extra.append('order',sort.field);
        extra.append('direc',sort.dir)
      }
      
      if(params && params.search && params.search!=""){        
        extra.append('search',params.search);
        reset = true;
      }else if((!params || !('search' in params)) && srh){
        extra.append('search',srh);
      }else{
        if(!params){
          params = {}
        }else if(params.search == ""){
          reset = true;
        }
        params.search = srh = null;
      }
      if (inputs.startDate) {
        extra.append('startDate',inputs.startDate);
      }
      if (inputs.endDate) {
        extra.append('endDate',inputs.endDate);
      }
      if(params && params.page){
        extra.append('page',params.page);
      }else if(!reset && cookies.suscriptions_list){     
        extra.append('page',cookies.suscriptions_list.paginate.current);
      }
      
    //}
    fetch(`${API_URI}/admin/suscriptions`,{method:'POST',body:extra})
    .then(res => res.json())
    .then(
      (result) => {
        const data = [];
        result.subscriptions.data.forEach(function(v,k){
          var items = [
              {name:(v.created_at)?v.created_at.substring(0,16):'',type:'text'},
              {name:v.country.name,type:'text'},
              {name:(v.student)?v.student.name:"",type:'text'},
              {name:(v.student)?v.student.lastname:"",type:'text'},
              {name:(v.student)?v.student.mail:"",type:'text'},
              {name:v.price,type:'text',align:"right"},
              {name:v.price_usd,type:'text',align:"right"},
              {name:v.subscription_id != null ? "Si" : "No",type:'text',align:"right"},
            ]
          data.push(items);
        })

        const countriesSumData = [];
        result.countriesSum.forEach(function(v,k){
          var items = [
              {name:v.name,type:'text'},
              {name:v.price,type:'text'},
              {name:v.count,type:'text'},
            ]
            countriesSumData.push(items);
        })

        globalValues.totalPrice = result.totalPrice;
        globalValues.totalSales = result.totalSales;
        setGlobalValues(globalValues);

        const paginate = {'current':result.subscriptions.current_page,'next':result.subscriptions.next_page_url,'prev':result.subscriptions.prev_page_url,'total':result.subscriptions.last_page};
        setTableData({
          ...tableData,
          ['data']:data,
          ['pagination'] : {'current':result.subscriptions.current_page,'next':result.subscriptions.next_page_url,'prev':result.subscriptions.prev_page_url,'total':result.subscriptions.last_page},
          ['sorting']:(params && params.field)?{'field':params.field,'dir':params.dir}:sort,
          ['search'] : (params && params.search)?params.search:srh,
        })

        setCountriesTableData({
          ...countriesTableData,
          ['data']:countriesSumData,
          ['sorting']:(params && params.field)?{'field':params.field,'dir':params.dir}:sort,
          ['search'] : (params && params.search)?params.search:srh,
        })

        setCookie('suscriptions_list',{
          'paginate':paginate,
          'sorting':(params && params.field)?{'field':params.field,'dir':params.dir}:sort,
          'search':(params && params.search)?params.search:srh,
        })
      })
  }
  
  function ordering(field,dir){
    const obj = {field:field,dir:dir}
    setTableData({
      ...tableData,
      ['data']:[],
      ['sorting']:obj
    })
    getListado(obj);
  }
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{textAlign:'right',marginTop:'-40px', zIndex:'1300'}}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomDatePicker
              formControlProps={{
                fullWidth: true
              }}
              required={false}
              error={null}
              value={inputs.startDate}
              id="startDate"
              label="Fecha Inicio"
              onChange={handleChanges}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomDatePicker
              formControlProps={{
                fullWidth: true
              }}
              required={false}
              error={null}
              value={inputs.endDate}
              id="endDate"
              label="Fecha Fin"
              onChange={handleChanges}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4} style={{marginTop:'50px', zIndex:'2040'}}>
            <Button type="submit" color="info" onClick={getListado}>
              Buscar
            </Button>
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
      { globalValues.totalPrice && globalValues.totalSales ? 
          <Card style={{paddingTop:'15px', paddingBottom:'15px', paddingLeft:'15px'}}>
            <div style={{width: '400px'}}>
              <span style={{width: 'fit-content', display: 'contents'}}>Total Ventas </span> <span style={{fontWeight: 'bold'}}> USD {globalValues.totalPrice.toLocaleString()} </span>
            </div>
            <div style={{width: '400px'}}>
              <span style={{width: 'fit-content', display: 'contents'}}> Cantidad Ventas </span> <span style={{fontWeight: 'bold'}}> {globalValues.totalSales.toLocaleString()} </span>
            </div>
            <div style={{width: '400px'}}>
              <span style={{width: 'fit-content', display: 'contents'}}>Precio Promedio </span> <span style={{fontWeight: 'bold'}}> USD {Math.round(globalValues.totalPrice/globalValues.totalSales).toLocaleString()} </span>
            </div>
          </Card> : "" }

        <Card>
          <CardBody>
            <Table
              tableHeaderColor="info"
              tableHead={tableData.headers}
              tableData={tableData.data}
              sortered={tableData.sorting}
              sortCallback={ordering}
              paginate={getListado}
              dtaPage = {tableData.pagination}
            />
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Table
              tableHeaderColor="info"
              tableHead={countriesTableData.headers}
              tableData={countriesTableData.data}
              sortCallback={ordering}
              paginate={getListado}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
   
  );
}
