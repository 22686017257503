import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import { NavLink } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";


import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';



const useStyles = makeStyles(styles);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default function CourseNavs(props) {
  const classes = useStyles(); 
  const [anchor,setAnchor] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleMenuItemClick = (event, index) => {
    
    
  };

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <GridContainer>
      
      {window.location.pathname == '/admin/cursos'?
      <GridItem xs={12} sm={12} md={6}>
        {/*  */}
        <Button onClick={(evnt)=>{setAnchor(evnt.currentTarget)}} color="white">Añadir nuevo</Button>

        <StyledMenu
          id="lock-menu"
          anchorEl={anchor}
          keepMounted
          
          open={Boolean(anchor)}
          onClose={handleClose}
        >
          {['Curso','Canción'].map((option, index) => (
            <MenuItem
              key={option}
              onClick={event => handleMenuItemClick(event, index)}
              style={{width:'175px'}}
            >
              {(index==0)?
                <NavLink
                  style={{color:'#999',width:'100%'}}
                  to={'curso-nuevo'}
                >
                  {option} 
                </NavLink>
              :
              <NavLink
                  style={{color:'#999',width:'100%'}}
                  to={'song'}
                >
                  {option} 
                </NavLink>
            }
              
              
            </MenuItem>
          ))}
        </StyledMenu>
      </GridItem>:
      ""
      
      }
    </GridContainer>


       
  );
}
