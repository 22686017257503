import React from "react";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';

export default function Pagination(props) {
    const {callback,actions} = props;

    function actionBtn(dir){
        const page = (dir)?actions.current+1:actions.current-1;
        
        callback({'page':page});
    }

    function actionFirstLast(dir){
        const page = (dir)?actions.total:1;
        
        callback({'page':page});
    }

    return (
        <div style={{width:'95%',textAlign:'right'}}>
            {(actions.prev)?
            <span>
                <span style={{width:'20px',display:'inline-block',cursor:'pointer'}} onClick={()=>{actionFirstLast(false)}}>
                    <SkipPreviousIcon style={{verticalAlign:'middle'}}/>
                </span>
                <span style={{width:'20px',display:'inline-block',cursor:'pointer'}} onClick={()=>{actionBtn(false)}}>
                    <ArrowLeftIcon style={{verticalAlign:'middle'}}/>
                </span>
            </span>
            :<span style={{width:'20px',display:'inline-block'}}></span>}
            <span style={{fontWeight:'bold',color:'black'}}>{actions.current} / {actions.total}</span>
            {(actions.next)?
            <span>
                <span style={{width:'20px',display:'inline-block',cursor:'pointer'}} onClick={()=>{actionBtn(true)}}>
                    <ArrowRightIcon style={{verticalAlign:'middle'}}/>
                </span>
                <span style={{width:'20px',display:'inline-block',cursor:'pointer'}} onClick={()=>{actionFirstLast(true)}}>
                    <SkipNextIcon style={{verticalAlign:'middle'}}/>
                </span>
            </span>
            :<span style={{width:'20px',display:'inline-block'}}></span>}
        </div>
    )
}



