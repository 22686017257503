import React from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Link from "components/Typography/Link";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { API_URI } from '../../helpers';

export default function SubListLessons(props) {
    const {data, onEdit, onDel, idCourse, reload} = props

    async function delLesson(id){
      const response = await fetch(`${API_URI}/admin/lesson/`+id+'/delete');
      const json  = await response.json();
      if(json.result){
        //onDel(id);
        reload()
      }
    } 

    async function reorderLesson(id,dir){
      const data = new FormData();
  
      data.append('course',idCourse);
      data.append('lesson',id);
      data.append('dir',dir);
      const res = await fetch(`${API_URI}/admin/courses/orderLesson`,{method:'POST',body:data});
      const json = await res.json();
  
      if(json.result){
        reload()
      }
    }

    
    return (
      <GridContainer>
      {data.map((item,k)=>{
        return (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7}>
                    <h4>{item.name}</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} style={{alignSelf:'center'}}>
                    <Link to={false} onClick={()=>{onEdit(item.id)}}>Editar Clase</Link>
                  </GridItem>

                  {
                        (data.length>1)?
                        <GridItem  xs={1} sm={1} md={1}>
                          {(k!=0)?
                            <div style={{height:'20px', paddingTop:'5px', paddingBottom:'5px'}}>
                              <ArrowDropUpIcon onClick={()=>{reorderLesson(item.id,'up')}} style={{cursor:'pointer'}}/>
                            </div>:''
                          }
                          
                          {(k!=data.length-1)?
                          <div style={{height:'20px', paddingTop:'5px', paddingBottom:'5px'}}>
                            <ArrowDropDownIcon onClick={()=>{reorderLesson(item.id,'down')}} style={{cursor:'pointer'}}/>
                          </div>:''
                        }
                          
                        </GridItem>:''
                      }
                  <GridItem xs={12} sm={12} md={1} style={{alignSelf:'center'}}>
                    <DeleteOutlinedIcon onClick={()=>{delLesson(item.id)}} style={{color:'#E8833F',cursor:'pointer'}} />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        )
      })}
      </GridContainer>
    );
  }