/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Person from "@material-ui/icons/Person";
//custom components TCDG
import ListCursos from "views/Cursos/ListCursos.js";
import FormCursos from "views/Cursos/FormCursos.js";
import FormSongs from "views/Cursos/FormSongs.js"
import CourseNavs from "components/CoursesNav/CourseNav.js"

import ListCategories from "views/Categories/List.js";
import ListSongCategories from "views/SongCategories/List.js";

import ListStudents from "views/Students/ListStudents.js"
// core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.js";
import FormStudents from "views/Students/FormStudents";
import ListTrash from "views/Trash/ListTrash.js";
import Countries from "views/Countries/Countries.js";
import Mailing from "views/Mailing/Mailing.js";
import MailHistory from "views/Mailing/MailHistory.js";
import Scripts from "views/Scripts/Scripts.js";
import Banner from "views/Banner/FormBanners.js";
import ListBanners from "views/Banner/ListBanners.js";
import ListSuscriptions from "views/Suscriptions/ListSuscriptions.js";
import Activation from "views/Activation/Activation.js";
import DashboardView from "views/DashboardView/DashboardView.js";
import ChangePassword from "views/ChangePassword/ChangePassword.js";

const dashboardRoutes = [
  {
    path: "/sales",
    buttonPath: "/sales",
    name: "Ventas",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Ventas",
    component: ListSuscriptions,
    layout: "/admin",
    profiles: null,
  },
  
  {
    path: "/changePassword",
    buttonPath: "/changePassword",
    name: "Cambio de Contraseña",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Cambio de Contraseña",
    component: ChangePassword,
    layout: "/admin",
    profiles: 'customerService',
  },

  /*{
    path: "/activation",
    name: "Activacion",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Activacion",
    component: Activation,
    layout: "/admin",
    profiles: 'customerService',
  },*/

  /*{
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Dashboard",
    component: DashboardView,
    layout: "/admin",
    profiles: null,
  },*/

  {
    path: "/cursos/:type?",
    buttonPath: "/cursos/Cursos",
    name: "Cursos",
    icon: Person,
    displayName:"Cursos",
    component: ListCursos,
    layout: "/admin",
    profiles: null,
  },
  {
    path: "/curso-nuevo/:courseId?",
    name: "nuevo-curso",
    icon: Person,
    displayName:"Agregar nuevo curso.",
    component: FormCursos,
    layout: "/admin",
    subRoute: true,
    profiles: null,
  },
  {
    path: "/song/:songId?",
    name: "song",
    icon: Person,
    displayName:"Agregar nueva canción.",
    component: FormSongs,
    nav:CourseNavs,
    layout: "/admin",
    subRoute: true,
    profiles: null,
  },  
  {
    path: "/categories",
    buttonPath: "/categories",
    name: "Categorias de Cursos",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Categorias de Cursos",
    component: ListCategories,
    layout: "/admin",
    profiles: null,
  },
  {
    path: "/cursos/:type?",
    buttonPath: "/cursos/Canciones",
    name: "Canciones",
    icon: Person,
    displayName:"Canciones",
    component: ListCursos,
    layout: "/admin",
    profiles: null,
  },
  {
    path: "/songCategories",
    buttonPath: "/songCategories",
    name: "Categorias de Canciones",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Categorias de Canciones",
    component: ListSongCategories,
    layout: "/admin",
    profiles: null,
  },
  {
    path: "/Students",
    buttonPath: "/Students",
    name: "Alumnos",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Alumnos",
    component: ListStudents,
    layout: "/admin",
    profiles: null,
  }
  ,

  {
    path: "/Student-new/:studentId?",
    name: "Crear Alumno",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Crear Alumno",
    component: FormStudents,
    layout: "/admin",
    subRoute: true,
    profiles: null,
  },

  {
    path: "/mailing/",
    buttonPath: "/mailing",
    name: "Mailing",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Mailing y notificaciones",
    component: Mailing,
    layout: "/admin",
    subRoute: false,
    profiles: null,
  },

  {
    path: "/history",
    buttonPath: "/history",
    name: "History",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Historial de Mails",
    component: MailHistory,
    layout: "/admin",
    subRoute: true,
    profiles: null,
  },

  {
    path: "/countries/",
    buttonPath: "/countries",
    name: "Precios",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Precios",
    component: Countries,
    layout: "/admin",
    subRoute: false,
    profiles: null,
  },

  {
    path: "/scripts",
    buttonPath: "/scripts",
    name: "Scripts",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Scripts",
    component: Scripts,
    layout: "/admin",
    profiles: null,
  },

  {
    path: "/promotions",
    buttonPath: "/promotions",
    name: "Promociones",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Promociones",
    component: ListBanners,
    layout: "/admin",
    profiles: null,
  },

  {
    path: "/promotion/:bannerId?",
    name: "Promociones",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Promociones",
    component: Banner,
    layout: "/admin",
    subRoute: true,
    profiles: null,
  },

  {
    path: "/trash",
    buttonPath: "/trash",
    name: "Papelera",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    displayName:"Papelera",
    component: ListTrash,
    layout: "/admin",
    profiles: null,
  }

];

export default dashboardRoutes;

